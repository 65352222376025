/* projects/customer/src/app/features/canvas/line/line.component.scss */
:host {
  position: absolute;
  top: 50%;
  left: 50%;
}
:host svg.line {
  overflow: visible;
  display: block;
  width: 4px;
  height: 4px;
}
:host svg.line .connectorGroup {
  pointer-events: all;
  cursor: pointer;
}
:host svg.line .connectorGroup path.connector {
  stroke: #b8b5b5;
}
:host svg.line .connectorGroup path.connector-border {
  stroke: transparent;
}
:host svg.line .connectorGroup:hover path.connector-border {
  stroke: #f1d795;
  filter: url(#blur-filter);
  opacity: 0.4;
}
:host svg.line .connectorGroup.selected path.connector {
  filter: brightness(60%) hue-rotate(190deg) saturate(155);
}
:host svg.line polyline.marker {
  fill: #b8b5b5;
}
:host svg.line.debug {
  stroke: red;
}
/*# sourceMappingURL=line.component.css.map */
