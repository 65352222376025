import { Inject, Injectable, InjectionToken } from '@angular/core';
import { IEnvironmentConfig } from './environment-config.interface';

export const ENV_CONFIG = new InjectionToken<IEnvironmentConfig>('ENV.config');

@Injectable({
    providedIn: 'root'
})
export class EnvConfigService {
    constructor(@Inject(ENV_CONFIG) private config: IEnvironmentConfig) {}

    get logLevel(): string {
        return this.config.logLevel;
    }
    get apiBaseUrl(): string {
        return this.config.apiBaseUrl;
    }
    get production(): Boolean {
        return this.config.production;
    }
}
