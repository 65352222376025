import { CommonModule } from '@angular/common';
import { Point } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { combineLatest, fromEvent, interval, race, Subject } from 'rxjs';
import { delay, map, takeUntil, throttle } from 'rxjs/operators';
import {
    IBlock,
    IConnectable,
    IConnectableBlock
} from '@customer/domain/block.interface';
import { determineLineDirection, Direction, IPoint } from '@drag-scale';
import { log } from '@vp-util';
import {
    ApplyMixins,
    Constructor,
    DestroyMixin,
    IDestroyMixin
} from '@customer/mixins';
import { CoordinateService } from '@customer/services/coordinate.service';
import { toDownLine, toLeftLine, toRightLine, toTopLine } from '@customer/util';

const r = (k, x) => /*Math.round(*/ k * x; /*)*/
const arrowDelta = 0; // play with 5px value, but there is issue with dragginh source block or destination block

type Arrow = 'back' | 'forward';

const mixins = ApplyMixins(class {}, [
    DestroyMixin
]) as Constructor<IDestroyMixin>;

@Component({
    selector: 'tm-line-draw',
    templateUrl: './line-draw.component.html',
    styleUrls: ['./line-draw.component.scss']
})
export class LineDrawComponent extends mixins implements OnInit, AfterViewInit {
    constructor(private coordinateService: CoordinateService) {
        super();
    }

    dExpr = '';

    S1: Point;
    S2: Point;
    Sm: Point;
    S1Slope: Point;
    S2Slope: Point;
    SmSlope: Point;
    SmSlope2: Point;
    SlopeLine = '';

    @Input()
    debug = true;

    @Input()
    from: IConnectableBlock;

    @ViewChild('path', { read: ElementRef, static: true })
    path: ElementRef;

    @Input()
    lineDirection: Direction = 'auto';
    @Input()
    arrow: Arrow = 'forward';

    private startBlock: IConnectableBlock;
    $disconnected = new Subject();

    tick = new Date().getTime();

    ngOnInit(): void {}
    ngAfterViewInit(): void {
        if (!this.from) throw new Error('start block is not specified');

        this.startBlock = this.from;
        const $mouseMove = fromEvent<MouseEvent>(document, 'mousemove').pipe(
            takeUntil(this.$destroy),
            map(event => this.coordinateService.getPointerPosition(event))
        );

        // this.endBlock.$destroy
        //     .pipe(takeUntil(this.$disconnected))
        //     .subscribe(() => {
        //         this.disconnect();
        //     });

        combineLatest([
            this.startBlock.$position,
            this.startBlock.$width,
            this.startBlock.$height,
            $mouseMove
        ])
            .pipe(
                takeUntil(this.$disconnected),
                // throttle(() => interval(5), {
                //     leading: false,
                //     trailing: true
                // }),
                delay(1) // to run js next turn
            )
            .subscribe(([srcBlock, w1, h1, cursor]) => {
                let line = this.buildLineCurve(srcBlock, w1, h1, cursor);
                this.path.nativeElement.setAttribute('d', line);
            });
    }

    private buildLineCurve(
        srcBlock: IPoint,
        w1: number,
        h1: number,
        cursor: IPoint
    ) {
        let line = '';
        let direction = this.lineDirection;

        if (direction == 'auto') {
            direction = determineLineDirection(
                {
                    /* center of block */
                    x: srcBlock.x + w1 / 2,
                    y: srcBlock.y - h1 / 2
                },
                cursor
            );
        }

        switch (direction) {
            case 'left':
                line = toLeftLine(srcBlock, w1, h1, cursor, 0, 0);
                break;
            case 'up':
                line = toTopLine(srcBlock, w1, h1, cursor, 0, 0);
                break;
            case 'down':
                line = toDownLine(srcBlock, w1, h1, cursor, 0, 0);
                break;
            case 'right':
            default:
                line = toRightLine(srcBlock, w1, h1, cursor, 0, 0);
                break;
        }
        return line;
    }

    disconnect() {
        this.$disconnected.next();
        //this.from.disconnect(null);
        //this.from.
    }
}
