import { DragDropRegistry, DropListRef } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { DOCUMENT } from '@angular/common';
import { ElementRef, Inject, Injectable, NgZone } from '@angular/core';
import { DragScaleService } from './services/drag-scale.service';
import { DragScaleSettingsProvider } from './structures';
import { TransformableRef } from './transformable-ref';
import { MoveSelectionService } from './services/move-selection.service';

export interface TransformableConfig {
    dragStartThreshold: number;
    pointerDirectionChangeThreshold: number;
    scaleOnWheelEnabled: boolean;
}

const DEFAULT_CONFIG: TransformableConfig = {
    scaleOnWheelEnabled: true,
    dragStartThreshold: 1,
    pointerDirectionChangeThreshold: 1
};

/**
 * Service that allows for transformation functionality to be attached to DOM elements.
 */
@Injectable({ providedIn: 'root' })
export class Transformable {
    constructor(
        @Inject(DOCUMENT) private _document: any,
        private _ngZone: NgZone,
        private _viewportRuler: ViewportRuler,
        private _dragDropRegistry: DragDropRegistry<
            TransformableRef,
            DropListRef
        >,
        private scaleService: DragScaleService,
        private moveSelectionService: MoveSelectionService
    ) {}

    /**
     * Turns an element into a transformable item.
     * @param element Element to which to attach the tranforming functionality.
     */
    createTransformable<T = any>(
        element: ElementRef<HTMLElement>,
        settingsProvider: DragScaleSettingsProvider,
        eventSource: ElementRef<HTMLElement> = null,
        container: TransformableRef = null
    ): TransformableRef {
        return new TransformableRef(
            element,
            eventSource,
            DEFAULT_CONFIG,
            this._ngZone,
            this._viewportRuler,
            this._dragDropRegistry,
            this.scaleService,
            this.moveSelectionService,
            container,
            settingsProvider
        );
    }
    createTransformableNoScale<T = any>(
        element: ElementRef<HTMLElement>,
        settingsProvider: DragScaleSettingsProvider,
        container: TransformableRef = null
    ): TransformableRef {
        const noScaleConf = DEFAULT_CONFIG;
        noScaleConf.scaleOnWheelEnabled = false;

        return new TransformableRef(
            element,
            element,
            noScaleConf,
            this._ngZone,
            this._viewportRuler,
            this._dragDropRegistry,
            this.scaleService,
            this.moveSelectionService,
            container,
            settingsProvider
        );
    }
}
