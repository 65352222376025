/* projects/customer/src/app/features/notes/notes.component.scss */
:host h1 {
  padding-left: 15px;
}
:host ::ng-deep .ql-editor.ql-blank::before {
  color: #bbb;
}
:host ::ng-deep .ql-toolbar.ql-snow,
:host ::ng-deep .ql-container.ql-snow {
  border-left: none !important;
  border-right: none !important;
}
/*# sourceMappingURL=notes.component.css.map */
