/* projects/customer/src/app/features/canvas/canvas.component.scss */
html {
  --vp-primary: #7aae38;
  --vp-block-selection-hover: red;
  --vp-text: #444;
  --mat-sidenav-content-background-color: #efefef;
  --vp-selection: #aaaad7;
  --vp-header-bg: transparent;
  --vp-header-text: var(--vp-text);
  --resize-handler-hover: var(--vp-primary);
  --vp-block-color-selected: var(--vp-selection);
}
:host {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 0;
}
canvas {
  box-sizing: border-box;
  background-color: #f5f5f5;
  position: absolute;
}
.zoomInfo {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--vp-header-bg);
  color: var(--vp-header-text);
  padding: 3px 2px 0px 8px;
  border-radius: 10px 0 0 0;
}
.blocks,
tm-scale-container {
  position: relative;
  height: 100%;
}
/*# sourceMappingURL=canvas.component.css.map */
