<svg id="line" class="line-draw">
    <defs>
        <marker
            id="markerArrow"
            refX="8"
            refY="4.5"
            markerUnits="userSpaceOnUse"
            orient="auto-start-reverse"
            markerWidth="8"
            markerHeight="9"
        >
            <polyline
                class="marker"
                points="0,0 8,4.5 0,9 1.5,4.5 0,0"
                stroke-width="0"
            />
        </marker>
    </defs>

    <path
        #path
        class="connector"
        [ngClass]="lineDirection"
        fill="none"
        stroke-width="1"
        [attr.marker-start]="arrow === 'back' ? 'url(#markerArrow)' : null"
        [attr.marker-end]="arrow === 'forward' ? 'url(#markerArrow)' : null"
    />
</svg>
