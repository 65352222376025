//import { environment } from '@customer/../environments/environment';

import { Injectable, Inject } from '@angular/core';
import { ENV_CONFIG } from './environment.service';
import { IEnvironmentConfig } from './environment-config.interface';

@Injectable({
    providedIn: 'root'
})
export class log {
    private static config: IEnvironmentConfig;

    constructor(@Inject(ENV_CONFIG) config: IEnvironmentConfig) {
        console.log(config);
        log.config = config;
        console.time('logTimer');
    }

    static debug = function (message?: any, ...optionalParams: any[]) {
        if (this.config.logLevel == 'info') return; // At 'Info' level (in production), debug messages are not logged
        //console.log(message, optionalParams);
        console.timeLog('logTimer', message);
    };

    static info = function (message?: any, ...optionalParams: any[]) {
        if (!this.config.production) {
            console.info(message, optionalParams);
        } else {
            // TBD: remote logging
            // sendToRemoteLoggingService(message);
        }
    };
    static error = function (message?: any, ...optionalParams: any[]) {
        console.error(message);
        if (!this.config.production) {
            // TBD: remote logging
            // sendErrorToRemoteLoggingService(message);
        }
    };
}
