/* projects/customer/src/app/features/canvas/scale-container/scale-container.component.scss */
:host {
  border: none;
  display: block;
  position: absolute;
  background-color: transparent;
  width: 1500px;
  height: 1000px;
  transform-box: border-box;
  transform-origin: 0 0;
}
:host.moving {
  transition: none;
}
.lt,
.rt,
.lb,
.rb {
  position: absolute;
  text-align: center;
}
.lt {
  right: 100%;
  bottom: 100%;
}
.rt {
  left: 100%;
  bottom: 100%;
}
.lb {
  right: 100%;
  top: 100%;
}
.rb {
  left: 100%;
  top: 100%;
}
/*# sourceMappingURL=scale-container.component.css.map */
