import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
    Component,
    HostListener,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { delay, map, shareReplay, takeUntil } from 'rxjs/operators';
import { BlockBrokerService, GlobalSettingsService } from './services';
import { SaveComponent } from './features/dialogs/save/save.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { registerIcons } from '@customer/util';
import { DestroyMixin, IDestroyMixin } from './mixins/destroy.mixin';
import { StoryBlock } from './domain/story-block';
import { NotesComponent } from './features/notes/notes.component';
import { GLOBAL_KEY_BINDING } from './key.binding.global';
import { DebugPanelComponent } from '@customer/features/debug-panel/debug-panel.component';
import { ApplyMixins, Constructor } from './mixins';

const mixins = ApplyMixins(class {}, [
    DestroyMixin
]) as Constructor<IDestroyMixin>;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends mixins implements OnInit {
    private static keyBindings = GLOBAL_KEY_BINDING;
    private editorSubscription: Subscription;
    isTouchPad: boolean;
    applicationTitle: string;
    //$destroy = new Subject<void>();
    $currentMapName = new BehaviorSubject<string>('');
    notesEditor: NotesComponent;
    debugPanel: DebugPanelComponent;

    @ViewChild('leftSidenav')
    leftSidenav: MatSidenav;
    @ViewChild('rightSidenav')
    rightSidenav: MatSidenav;
    @ViewChild('rightContent', { read: ViewContainerRef })
    rightContent: ViewContainerRef;

    constructor(
        private breakpointObserver: BreakpointObserver,
        matIconRegistry: MatIconRegistry,
        domSanitizer: DomSanitizer,
        private settingsService: GlobalSettingsService,
        private blockBroker: BlockBrokerService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar
    ) {
        super(arguments);

        this.$isHandset = breakpointObserver.observe(Breakpoints.Handset).pipe(
            map(result => result.matches),
            shareReplay()
        );

        registerIcons(matIconRegistry, domSanitizer);
        this.isTouchPad = settingsService.$settings.value.isTouchPad;
        this.applicationTitle =
            settingsService.$settings.value.applicationTitle;
    }

    ngOnInit(): void {
        this.blockBroker.$mapLoaded
            .pipe(takeUntil(this.$destroy), delay(0))
            .subscribe(mapName => {
                this.closeLeftSidenav();
                this.$currentMapName.next(mapName);
            });

        this.blockBroker.$showNote
            .pipe(takeUntil(this.$destroy), delay(0))
            .subscribe(block => this.showNote(block));
        this.blockBroker.$showDebug
            .pipe(takeUntil(this.$destroy), delay(0))
            .subscribe(this.showDebug);
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(ev: KeyboardEvent) {
        AppComponent.keyBindings.find(x => x.predicate(ev))?.action(this, ev);
    }

    $isHandset: Observable<boolean>;

    touchModeChanged(evt: MatSlideToggleChange) {
        this.settingsService.setTouchPadMode(evt.checked);
    }

    showNote(block: StoryBlock) {
        if (!this.notesEditor) {
            this.clearRightSidenav();
            const componentRef =
                this.rightContent.createComponent(NotesComponent);
            this.notesEditor = componentRef.instance;
            this.notesEditor.model = block.notes;
            this.editorSubscription = this.notesEditor.modelChange
                .pipe(takeUntil(this.$destroy))
                .subscribe(m => {
                    block.notes = m;
                });
            this.rightSidenav.open();
        }
    }
    showDebug() {
        if (!this.debugPanel) {
            this.clearRightSidenav();
            const componentRef =
                this.rightContent.createComponent(DebugPanelComponent);
            this.debugPanel = componentRef.instance;
            this.rightSidenav.open();
        }
    }

    clearRightSidenav() {
        this.editorSubscription?.unsubscribe();
        this.rightContent.clear();
        this.debugPanel = null;
        this.notesEditor = null;
    }

    closeRightSidenav() {
        this.clearRightSidenav();
        this.rightSidenav.close();
    }
    closeLeftSidenav() {
        this.leftSidenav.close();
    }

    saveMap() {
        this.openSaveDialog(this.$currentMapName.value)
            .pipe(takeUntil(this.$destroy))
            .subscribe(x => {
                if (x && x instanceof BehaviorSubject) {
                    this.blockBroker.saveMap(x.value);
                    this.$currentMapName.next(x.value);
                    this.closeLeftSidenav();
                }
            });
    }
    newMap() {
        this.blockBroker.newMap();
    }

    openSaveDialog(currentMapName: string): Observable<any> {
        const dialogRef = this.dialog.open(SaveComponent, {
            id: SaveComponent.DIALOG_ID,
            width: '550px',
            data: { mapName: currentMapName }
        });
        return dialogRef.afterClosed();
    }
}
