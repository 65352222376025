import { KEY } from '@customer/util/key.const';
import { ScaleContainerComponent } from './scale-container.component';

export var SCALE_CONTAINER_KEY_BINDING = [
    {
        /* Alt+E => Extract selected blocks to new story-map  */
        predicate: (
            scaleContainer: ScaleContainerComponent,
            ev: KeyboardEvent
        ) => {
            return ev.code === KEY.E && ev.altKey;
        },
        action: (
            scaleContainer: ScaleContainerComponent,
            ev: KeyboardEvent
        ): void => {
            scaleContainer.extractStoryMap();
            ev.preventDefault();
        }
    },
    {
        /* Alt+X => Draw a line  */
        predicate: (
            scaleContainer: ScaleContainerComponent,
            ev: KeyboardEvent
        ) => {
            return ev.code === KEY.X && ev.altKey;
        },
        action: (
            scaleContainer: ScaleContainerComponent,
            ev: KeyboardEvent
        ): void => {
            scaleContainer.drawLine();
            ev.preventDefault();
        }
    }
];
