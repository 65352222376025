@for (block of (storyMap | async).blocks; track block; let i = $index) {
<tm-block [block]="block" [dragContainer]="dragWorld"></tm-block>
}
<tm-selection></tm-selection>

<!-- Blur Filter -->
<svg width="0" height="0">
    <defs>
        <filter id="blur-filter">
            <feGaussianBlur stdDeviation="3" />
        </filter>
    </defs>
</svg>
