import { JsonProperty, Serializable } from '../../ts-json-serializer';
import { IBlock } from './block.interface';

export type Direction = 'up' | 'left' | 'down' | 'right' | 'auto';

const getDestinationId = (ids: string[], instance: Line) => {
    return instance.destination.id;
};

export var invertDirection = (dir: Direction) => {
    switch (dir) {
        case 'down':
            return 'up';
        case 'up':
            return 'down';
        case 'left':
            return 'right';
        case 'right':
            return 'left';
        case 'auto':
            return 'auto';
        default:
            throw new Error(`Invalid argument -> ${dir}`);
    }
};

@Serializable()
export class Line {
    constructor(dest: IBlock, direction: Direction) {
        this.destination = dest;
        this.direction = direction;
    }
    destination: IBlock;

    @JsonProperty({
        beforeSerialize: getDestinationId
    })
    destinationId = '';

    @JsonProperty()
    direction: Direction;
}

export class LineDraw extends Line {
    constructor() {
        super(null, 'auto');
    }
    isDrawActive = true;
}
