import { Injectable } from '@angular/core';
import { StoryMap } from '@customer/domain/story-map';
import { deserialize, serialize } from '../../ts-json-serializer';

@Injectable({
    providedIn: 'root'
})
export class MapSerializerFacade {
    constructor() {}

    Serialize(map: StoryMap): string {
        return JSON.stringify(serialize(map, true));
    }

    deserialize<T>(story: string, type: new (...params: Array<any>) => T) {
        return deserialize<T>(story, type);
    }
}
