/* projects/customer/src/app/features/buttons/button/button.component.scss */
:host {
  padding: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  vertical-align: middle;
  border-radius: 3px;
  box-sizing: border-box;
}
:host:hover {
  background-color: hsl(0, 0%, 55%);
}
img {
  width: 35px;
  text-align: left;
}
/*# sourceMappingURL=button.component.css.map */
