/* projects/customer/src/app/features/canvas/line-draw/line-draw.component.scss */
:host {
  position: absolute;
  top: 50%;
  left: 50%;
}
:host svg {
  overflow: visible;
  display: block;
  width: 4px;
  height: 4px;
}
:host svg path.connector {
  stroke: #da12e4;
}
:host svg polyline.marker {
  fill: #da12e4;
}
/*# sourceMappingURL=line-draw.component.css.map */
