import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import {
    ApplyMixins,
    Constructor,
    DestroyMixin,
    IDestroyMixin
} from '@customer/mixins';

const mixins = ApplyMixins(class {}, [
    DestroyMixin
]) as Constructor<IDestroyMixin>;

@Component({
    selector: 'tm-save',
    templateUrl: './save.component.html',
    styleUrls: ['./save.component.scss']
})
export class SaveComponent extends mixins {
    name: string;
    static DIALOG_ID: 'saveDialog';
    dialogResult: BehaviorSubject<string>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { mapName: string }) {
        //super(arguments);
        super();
        this.dialogResult = new BehaviorSubject(data.mapName);
        this.name = data.mapName;
    }

    save() {
        this.dialogResult.next(this.name);
    }
}
