import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import { NodeText } from '@customer/domain';
import Quill, { QuillOptionsStatic, KeyboardStatic } from 'quill';
import { Delta } from 'quill-delta';

export const quill_block_config: QuillOptionsStatic = {
    theme: 'bubble',
    modules: {
        toolbar: false
        /* keyboard: {
            bindings: quill_block_bindings
        }, */
    },
    placeholder: ''
};

@Component({
    selector: 'tm-editor-input',
    template: ''
})
export class EditorInputComponent implements AfterViewInit, OnDestroy {
    constructor(private elRef: ElementRef<HTMLElement>) {}

    componentRef: ComponentRef<EditorInputComponent>;
    _model = '';

    @Input('model')
    set model(html: string) {
        this._model = html;
        if (this.q) {
            this.q.root.innerHTML = this._model;
            this.q.root.focus();
        }
    }

    @Input()
    config = quill_block_config;

    @HostBinding('class.hasFocus')
    get hasFocus(): boolean {
        return document.activeElement == this.elRef.nativeElement;
    }

    @Output()
    modelChanged = new EventEmitter<string>();

    q: Quill;
    textChangeWrapper = (d, p, s) => this.onTextChange(d, p, s);

    @HostListener('click', ['$event'])
    onClick(ev: MouseEvent) {
        // do not pass click to outside
        ev.stopPropagation();
        ev.preventDefault();
    }

    ngAfterViewInit(): void {
        const ne = this.elRef.nativeElement;
        let q = (this.q = new Quill(ne, this.config));
        q.on('text-change', this.textChangeWrapper);
        delete q.keyboard['bindings']['9']; // remove tab
        q.keyboard.addBinding(
            // link formatting
            {
                key: 'K',
                shortKey: true
            },
            function (range, context) {
                var value = prompt('Enter link URL:');
                q.format('link', value, 'user');
            }
        );

        q.root.innerHTML = this._model;
        q.enable();
        q.root.focus();
    }
    ngOnDestroy(): void {
        this.q.off('text-change', this.textChangeWrapper);
    }
    onTextChange(delta: Delta, prev: Delta, source: string) {
        this.modelChanged.emit(this.q.root.innerHTML);
    }
}
