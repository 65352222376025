/* projects/customer/src/app/features/canvas/block/block.component.scss */
@font-face {
  font-family: NotoSans;
  src: url("./media/NotoSans-Regular.ttf") format("opentype");
}
html {
  --vp-primary: #7aae38;
  --vp-block-selection-hover: red;
  --vp-text: #444;
  --mat-sidenav-content-background-color: #efefef;
  --vp-selection: #aaaad7;
  --vp-header-bg: transparent;
  --vp-header-text: var(--vp-text);
  --resize-handler-hover: var(--vp-primary);
  --vp-block-color-selected: var(--vp-selection);
}
.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
html {
  --vp-primary: #7aae38;
  --vp-block-selection-hover: red;
  --vp-text: #444;
  --mat-sidenav-content-background-color: #efefef;
  --vp-selection: #aaaad7;
  --vp-header-bg: transparent;
  --vp-header-text: var(--vp-text);
  --resize-handler-hover: var(--vp-primary);
  --vp-block-color-selected: var(--vp-selection);
}
:host {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  min-width: 50px;
  min-height: 44px;
  border-radius: 3px;
}
.block {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: nowrap;
  z-index: 2;
  box-sizing: border-box;
  min-width: 50px;
  min-height: 44px;
  border-radius: 3px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  background-color: white;
  box-shadow: 1px 1px 7px -4px #888;
  padding: 1px 0px;
}
.block:hover {
  border-color: var(--vp-block-color-selected);
}
.block:hover .resize-handle.left,
.block:hover .resize-handle.right {
  border-color: var(--vp-block-color-selected);
}
.block:hover.forSelection {
  border-color: var(--vp-block-selection-hover);
  border-width: 2px;
  padding: 0px 0px;
  cursor: pointer;
}
.block.selected {
  border-color: #6565b6;
  box-shadow: #6565b6 0px 0px 0px 1px;
}
.block.editMode {
  border-color: red;
}
.resize-handle {
  width: 3px;
}
.resize-handle.left {
  border-radius: 2px 0px 0px 2px;
  border-left: 1px solid #ccc;
}
.resize-handle.right {
  border-radius: 0px 2px 2px 0px;
  border-right: 1px solid #ccc;
}
.resize-handle.right:hover {
  cursor: ew-resize;
  background-color: var(--resize-handler-hover);
}
.resize-handle .debug {
  position: relative;
}
.middle-content {
  flex-grow: 1;
  padding: 3px 5px;
}
:host-context(.ql-container) .ql-editor {
  box-sizing: border-box;
  width: 100%;
  font-family: NotoSans;
  font-size: 16px;
  line-height: 1.3em;
  margin: 0;
  padding: 0.5em 0.7em;
  display: block;
  border: 0 none white;
  border-radius: 0;
  text-align: left;
  overflow: hidden;
  outline: none;
  resize: none;
  font-size: 16px;
}
.ql-editor {
  overflow-wrap: anywhere;
}
.position {
  font-size: 10px;
  position: absolute;
  top: 100%;
}
/*# sourceMappingURL=block.component.css.map */
