import { IPoint } from '@drag-scale';
import { BehaviorSubject, Subject } from 'rxjs';
import { JsonProperty, Serializable } from '../../ts-json-serializer';
import { IBlock, IConnectableBlock, IResizable } from './block.interface';
import { Line } from './line';
import { NodeText } from './node-text';

const getValue = (s: { value: any }) => s?.value;
const buildBehaviorSubject = (s: any) => new BehaviorSubject(s);
const getParentId = (ids: string[], instance: StoryBlock) => {
    return instance.parent?.id;
};

@Serializable()
export class StoryBlock implements IConnectableBlock, IResizable {
    constructor(text: string, position: IPoint, id: string) {
        this.text = text;
        this.id = id;
        this.$position = new BehaviorSubject<IPoint>(position);
        this.$movingBackLines = new BehaviorSubject<Line[]>([]);
    }

    connect(toBlock: IBlock) {
        throw new Error('Method not implemented.');
    }
    disconnect(endBlock: IBlock) {
        let i = this.outgoingLines.findIndex(
            (line, index, arr) => line.destination.id === endBlock.id
        );
        if (i >= 0) this.outgoingLines.splice(i, 1);
    }

    //=== Not serializble
    $movingBackLines: Subject<Line[]>;
    parent: StoryBlock;
    $destroy = new Subject<void>();
    $editMode = new BehaviorSubject(false);
    $selected = new BehaviorSubject(false);
    $isMoving = new BehaviorSubject(false);

    //==== Serializable properties
    @JsonProperty() id: string;
    @JsonProperty({ beforeSerialize: getParentId }) parentId = '';
    @JsonProperty() text: string;
    @JsonProperty({
        type: Line
    })
    outgoingLines: Line[] = [];
    @JsonProperty({
        name: 'position',
        beforeSerialize: getValue,
        afterDeserialize: buildBehaviorSubject
    })
    $position: BehaviorSubject<IPoint>;

    @JsonProperty({
        name: 'autoWidth',
        beforeSerialize: getValue,
        afterDeserialize: buildBehaviorSubject
    })
    $autoWidth = new BehaviorSubject(true);

    @JsonProperty({
        name: 'width',
        beforeSerialize: getValue,
        afterDeserialize: buildBehaviorSubject
    })
    $width = new BehaviorSubject(0);

    @JsonProperty({
        name: 'height',
        beforeSerialize: getValue,
        afterDeserialize: buildBehaviorSubject
    })
    $height = new BehaviorSubject(0);

    @JsonProperty({
        name: 'notes'
    })
    notes = '';
}
