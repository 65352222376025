export type Constructor<T> = new (...args: any[]) => T;

export function ApplyMixins<T>(
    Base: Constructor<T> = class {} as Constructor<T>,
    mixins: ((Base: Constructor<T>) => Constructor<T>)[]
): Constructor<T> {
    return mixins.reduce((acc, mixin) => mixin(acc), Base);
}

export * from './editable.mixin';
export * from './destroy.mixin';
