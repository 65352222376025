import { IPoint } from './structures';

export class TransformState {
    scale: number;
    position: IPoint;
    constructor(scale: number, position: IPoint) {
        this.scale = scale;
        this.position = position;
    }
}
