import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap, take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DragScaleService {
    $scaleFactor = new BehaviorSubject<number>(100);
    constructor() {}
}
