/* projects/customer/src/app/app.component.scss */
router-outlet > * {
  margin-top: var(--mat-toolbar-standard-height);
}
.sidenav-container {
  height: 100%;
}
.mat-drawer-content {
  overflow: initial;
}
.sidenav {
  width: 200px;
}
.rightPanel {
  width: 40%;
  min-width: 800px;
}
.fullH {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.fullH .right {
  position: absolute;
  right: 10px;
  text-align: center;
}
.fullH .right .mat-icon-button {
  width: auto;
}
.fullH .rightContent {
  display: block;
  flex-grow: 1;
  padding-left: 1em;
}
.fullH .actionLine {
  padding: 1em;
}
.sidenav .mat-toolbar {
  background: inherit;
}
mat-toolbar.mat-primary {
  position: absolute;
  top: 0;
  z-index: 1;
  display: inline-flex;
  width: auto;
  background-color: rgba(240, 240, 240, 0.5647058824);
  backdrop-filter: blur(2px);
  border: 1px solid #f0f0f0;
  box-shadow: 2px 2px 3px #f0f0f0;
}
mat-toolbar.mat-primary .logo {
  padding: 0 10px;
}
mat-toolbar.mat-primary .logo svg g path {
  fill: white;
}
mat-toolbar.mat-primary .spacer {
  flex-grow: 1;
}
mat-toolbar.mat-primary .spacer_full {
  flex: 1 1 auto;
}
mat-toolbar.userProfile {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: inline-flex;
  width: auto;
  background-color: rgba(240, 240, 240, 0.5647058824);
  backdrop-filter: blur(2px);
  border: 1px solid #f0f0f0;
  box-shadow: 2px 2px 3px #f0f0f0;
}
mat-sidenav-content {
  display: flex;
  flex-direction: column;
}
.toggler {
  position: absolute;
  height: 50px;
  width: 30px;
  border-radius: 10px 0px 0px 10px;
  right: 0;
  top: 50%;
  background-image: url("./media/left_chevron.svg");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}
.toggler:hover {
  filter: brightness(95%);
  background-color: #f9f9f9;
}
.ql-editor {
  overflow-wrap: anywhere;
}
/*# sourceMappingURL=app.component.css.map */
