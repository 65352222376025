/* projects/customer/src/app/features/canvas/scale-container/selection.component.scss */
:host {
  padding: 0;
  margin: 0;
  background-color: rgba(14, 37, 241, 0.1333333333);
  border: 1px solid rgba(20, 106, 146, 0.6);
  position: absolute;
  box-sizing: content-box;
  width: 0px;
  height: 0px;
  display: none;
}
:host.isActive {
  display: block;
}
/*# sourceMappingURL=selection.component.css.map */
