// src/lib/my-lib.module.ts
import { NgModule, ModuleWithProviders } from '@angular/core';
import { log } from './logging.service';
import { IEnvironmentConfig } from './environment-config.interface';
import { ENV_CONFIG } from './environment.service';

@NgModule({
    providers: [log]
})
export class VpUtilModule {
    static forRoot(
        config: IEnvironmentConfig
    ): ModuleWithProviders<VpUtilModule> {
        return {
            ngModule: VpUtilModule,
            providers: [{ provide: ENV_CONFIG, useValue: config }]
        };
    }
}
