import {
    NgZone,
    OnInit,
    OnDestroy,
    Directive,
    Output,
    EventEmitter,
    ElementRef
} from '@angular/core';

@Directive({
    selector: '[tmZoom]'
})
export class ZoomDirective implements OnInit, OnDestroy {
    private scrollTop: number;
    @Output() zoom = new EventEmitter<number>();

    constructor(private ngZone: NgZone) {}

    ngOnInit() {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener('wheel', this.scrollHandler, true);
        });
    }

    ngOnDestroy() {
        window.removeEventListener('wheel', this.scrollHandler, true);
    }

    scrollHandler = (evt: Event): void => {
        if (true /* if need to interact with angular */) {
            this.ngZone.run(() => {
                // this.tellAngular();
                this.zoom.emit((evt as WheelEvent).deltaY);
                evt.cancelBubble = true;
            });
        }
    };
}
