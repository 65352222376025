import { Injectable } from '@angular/core';
import { IPoint } from '@drag-scale';
import { log } from '@vp-util';
import { Subject } from 'rxjs';
import {
    ApplyMixins,
    Constructor,
    IDestroyMixin,
    DestroyMixin
} from '@customer/mixins';
import { takeUntil } from 'rxjs/operators';

const mixins = ApplyMixins(class {}, [
    DestroyMixin
]) as Constructor<IDestroyMixin>;

@Injectable({
    providedIn: 'root'
})
export class MoveSelectionService extends mixins implements IDestroyMixin {
    constructor() {
        super();

        this.$deltaMove.pipe(takeUntil(this.$destroy));
        // .subscribe(this.moveSelection);
    }

    $deltaMove = new Subject<IPoint>();

    // moveSelection = (delta: IPoint) => {
    //     log.debug(`delta dX:${delta.x}, dY:${delta.y}`);
    // };
}
