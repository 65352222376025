<h2 mat-dialog-title>Save story tree</h2>
<mat-dialog-content>
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input
            type="text"
            matInput
            required
            [(ngModel)]="name"
            placeholder="type name here"
        />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]>Cancel</button>
    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button
        mat-button
        (click)="save()"
        [mat-dialog-close]="dialogResult"
        cdkFocusInitial
    >
        Save
    </button>
</mat-dialog-actions>
