import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'tm-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
    constructor() {}

    @Input()
    type: string;

    ngOnInit() {}

    get url(): string {
        return `assets/${this.type}.svg`;
    }
}
