import { ViewportRuler } from '@angular/cdk/scrolling';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    NgZone,
    OnInit
} from '@angular/core';
import {
    debugPoint,
    getMoveTransform,
    getScaleTransform,
    isTouchEvent,
    IPoint,
    prepareRect,
    IRect,
    TransformableRef,
    TransformState
} from '@drag-scale';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { concatAll, startWith, takeUntil } from 'rxjs/operators';
import { BlockBrokerService } from '@customer/services';
import {
    ApplyMixins,
    Constructor,
    DestroyMixin,
    IDestroyMixin
} from '@customer/mixins';
import { log } from '@vp-util';
import { CoordinateService } from '@customer/services/coordinate.service';

declare type scroll = { top: number; left: number };

const mixins = ApplyMixins(class {}, [
    DestroyMixin
]) as Constructor<IDestroyMixin>;

@Component({
    selector: 'tm-selection',
    template: '',
    styleUrls: ['./selection.component.scss']
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectionComponent extends mixins implements OnInit {
    $position = new BehaviorSubject<IPoint>({ x: 0, y: 0 });
    $width = new BehaviorSubject<number>(0);
    $height = new BehaviorSubject<number>(0);
    $overlapped = new Subject<Array<string>>();

    source: ElementRef<HTMLElement>;
    dragWorld: TransformableRef;
    scale: number = 1;

    mouseMoveWrapper = e => this.mouseMove(e);
    mouseUpWrapper = e => this.mouseUp(e);
    mouseClickWrapper = e => this.mouseClick(e);

    private _scrollSubscription = Subscription.EMPTY;
    private _stateSubscription = Subscription.EMPTY;
    private _startPosition: IPoint;
    private _blocks: Map<string, IRect>;
    private _selectedBlocks: Set<string>;

    @HostBinding('style.width')
    width: string;
    @HostBinding('style.height')
    height: string;
    @HostBinding('class.isActive')
    isActive: boolean = false;
    @HostBinding('class.isMoving')
    isMoving: boolean = false;

    constructor(
        private el: ElementRef<HTMLElement>,
        private blockBrocker: BlockBrokerService,
        private coordinateService: CoordinateService,
        private cdr: ChangeDetectorRef,
        private zone: NgZone
    ) {
        super(arguments);
    }

    ngOnInit(): void {}

    start(
        ev: MouseEvent,
        eventContainer: ElementRef<HTMLElement>,
        blockCache: Map<string, IRect>
    ) {
        this.zone.runOutsideAngular(() => {
            this.source = eventContainer;
            this._blocks = blockCache;
            this.isMoving = false;
            this._selectedBlocks = new Set<string>();

            this._stateSubscription = this.dragWorld.$transformState.subscribe(
                ts => (this.scale = ts.scale)
            );

            eventContainer.nativeElement.addEventListener(
                'mousemove',
                this.mouseMoveWrapper,
                true
            );
            eventContainer.nativeElement.addEventListener(
                'mouseup',
                this.mouseUpWrapper,
                { capture: true, once: true }
            );
            eventContainer.nativeElement.addEventListener(
                'click',
                this.mouseClickWrapper,
                { capture: false, once: true }
            );

            this._startPosition = this.coordinateService.getPointerPosition(ev);

            // set inital point os selection - TODO: connect with world parameters
            this.applyElementTransform(1, this._startPosition);
        });
    }

    mouseMove(ev: MouseEvent) {
        ev.preventDefault();

        const pointerPosition = this.coordinateService.getPointerPosition(ev);
        let rectSelection = prepareRect(this._startPosition, pointerPosition);
        this.width = `${rectSelection.width}px`;
        this.height = `${rectSelection.height}px`;

        this.applyElementTransform(1, rectSelection);

        //check overlapping
        let overlappingIds = this.getOverlapping(
            this.el.nativeElement.getBoundingClientRect()
        );

        this.updateSelection(overlappingIds, [...this._selectedBlocks]);

        //this.$overlapped.next(overlapping);
        this.isMoving = true;
        this.isActive = true;
        this.blockBrocker.$isSelectionActive.next(true);
        this.cdr.markForCheck();
    }
    mouseClick(ev: MouseEvent) {
        if (this.blockBrocker.$isSelectionActive.value && this.isMoving) {
            // stop any other click handlers (especially on blocks listeners)
            this.blockBrocker.$isSelectionActive.next(false);
            ev.stopPropagation();
        }
    }
    mouseUp(ev: MouseEvent) {
        this.source.nativeElement.removeEventListener(
            'mousemove',
            this.mouseMoveWrapper,
            true
        );

        this._scrollSubscription.unsubscribe();
        this._stateSubscription.unsubscribe();
        this.isActive = false;
        this.width = '';
        this.height = '';
    }

    private getOverlapping(selection: IRect): Array<string> {
        let overlapping = [];
        this._blocks.forEach((r, k) => {
            let over = !(
                selection.x + selection.width < r.x ||
                selection.x > r.x + r.width ||
                selection.y > r.y + r.height ||
                selection.y + selection.height < r.y
            );
            if (over) overlapping.push(k);
        });
        return overlapping;
    }
    private updateSelection(currentIds: string[], previousIds: string[]) {
        let currentSet = new Set<string>(currentIds);
        let previousSet = new Set<string>(previousIds);
        const toRemove = previousIds.filter(id => !currentSet.has(id));
        const toAdd = currentIds.filter(id => !previousSet.has(id));

        // add to selection
        if (toAdd.length > 0) {
            this.blockBrocker.selectBlocks(toAdd);
            toAdd.forEach(item => this._selectedBlocks.add(item));
        }
        if (toRemove.length > 0) {
            this.blockBrocker.deselectBlocks(toRemove);
            toRemove.forEach(item => this._selectedBlocks.delete(item));
        }
    }

    private applyElementTransform(scale: number, pos: IPoint) {
        let transform = '';
        if (pos) {
            transform += getMoveTransform(pos);
        }
        if (scale) {
            transform += ' ' + getScaleTransform(scale);
        }
        this.el.nativeElement.style.transformOrigin = '0 0';
        this.el.nativeElement.style.transformBox = 'border-box';
        this.el.nativeElement.style.transform = transform;
    }
}
