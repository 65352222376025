// block-resizer.directive.ts

import {
    Directive,
    ElementRef,
    HostListener,
    Input,
    Renderer2
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IResizable } from '../domain';

@Directive({
    selector: '[BlockResizer]'
})
export class BlockResizerDirective {
    private isResizing = false;
    private previousX: number;
    private mouseMoveListenerRemover;
    private mouseUpListenerRemover;

    constructor(private renderer: Renderer2) {}

    @Input('minWidth') minWidth: number;
    @Input('resizable') resizable: IResizable;
    public currentWidth: number;

    @HostListener('mousedown', ['$event'])
    @HostListener('touchstart', ['$event'])
    onMouseDown(event: MouseEvent | TouchEvent): void {
        event.stopPropagation();
        this.isResizing = true;
        this.previousX = this.getClientX(event);
        this.mouseMoveListenerRemover = this.renderer.listen(
            'document',
            'mousemove',
            this.onMouseMove.bind(this)
        );
        this.mouseUpListenerRemover = this.renderer.listen(
            'document',
            'mouseup',
            this.onMouseUp.bind(this)
        );
        this.resizable.$autoWidth.next(false);
    }

    onMouseUp(event: MouseEvent | TouchEvent): void {
        event.stopPropagation();
        this.isResizing = false;
        // Remove listeners
        if (this.mouseMoveListenerRemover) this.mouseMoveListenerRemover();
        if (this.mouseUpListenerRemover) this.mouseUpListenerRemover();
    }

    onMouseMove(event: MouseEvent | TouchEvent): void {
        if (!this.isResizing) return;
        event.stopPropagation();

        const current = this.resizable.$width.value
            ? this.resizable.$width.value
            : this.currentWidth;
        const deltaX = this.getClientX(event) - this.previousX;
        const newVal = Math.max(current + deltaX, this.minWidth);
        console.log(newVal);
        this.resizable.$width.next(newVal);
        this.previousX = this.getClientX(event);
    }

    private getClientX(event: MouseEvent | TouchEvent): number {
        if (event instanceof MouseEvent) return event.clientX;
        else if ((event as TouchEvent).touches.length == 1)
            return (event as TouchEvent).touches[0].clientX;
    }
}
