import { Component } from '@angular/core';
import { BlockBrokerService } from '@customer/services';

@Component({
    selector: 'tm-debug-panel',
    templateUrl: './debug-panel.component.html',
    styleUrls: ['./debug-panel.component.scss']
})
export class DebugPanelComponent {
    /**
     *
     */
    constructor(blockBroker: BlockBrokerService) {
        console.log(blockBroker.$storyMap.value);
    }

    testJson = { a: { b: { c: [1, 2, 3, 4] }, x: 'sdsdsd' } };
}
