import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Constructor } from '.';
import { log } from '@vp-util';

export interface IDestroyMixin extends OnDestroy {
    $destroy: Subject<void>;
}

export function DestroyMixin<T extends Constructor<any>>(
    Base: T
): T & Constructor<IDestroyMixin> {
    return class extends Base implements IDestroyMixin {
        $destroy = new Subject<void>();

        ngOnDestroy(): void {
            this.$destroy.next();
            this.$destroy.complete();
            log.debug(`destory -> ${this}`);
        }
    };
}
