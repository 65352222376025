import { JsonProperty, Serializable } from '../../ts-json-serializer';
import { StoryBlock } from './story-block';

export const MAP_FORMAT_VERSION = 'v.1.0.2';

@Serializable()
export class StoryMap {
    @JsonProperty()
    formatVersion = MAP_FORMAT_VERSION;

    @JsonProperty({
        type: StoryBlock
    })
    blocks: StoryBlock[] = [];
    @JsonProperty()
    name: string;

    @JsonProperty({
        name: '_id'
    })
    id: string;

    constructor() {}
}
