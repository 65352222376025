import { NgModule } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { DragScaleService, MoveSelectionService } from '@drag-scale';

@NgModule({
    declarations: [],
    imports: [HammerModule],
    exports: [],
    providers: [DragScaleService, MoveSelectionService]
})
export class DragScaleModule {}
