<a mat-list-item routerLink="/new" routerLinkActive="active">
    <mat-icon class="gray" svgIcon="new" aria-label="new map">New</mat-icon
    >&nbsp;Новая карта
</a>

<h2>Local maps</h2>
@for (mapKey of localMaps; track mapKey) {
<a
    mat-list-item
    routerLink="/map"
    [queryParams]="{ m: mapKey, source: 'local' }"
    routerLinkActive="active"
    >{{ mapKey }}</a
>
}
<h2>Server maps</h2>
@for (map of maps|async; track map._id) {
<a
    mat-list-item
    routerLink="/map"
    [queryParams]="{ m: map._id, source: 'remote' }"
    routerLinkActive="active"
    >{{ map.name }}</a
>
}
