export var KEY = {
    TAB: 'Tab',
    DELETE: 'Delete',
    ENTER: 'Enter',
    ESC: 'Escape',
    CTRL: 'Control',
    SHIFT: 'Shift',
    BACKSPACE: 'Backspace',
    SPACE: 'Space',
    I: 'KeyI',
    D: 'KeyD',
    B: 'KeyB',
    E: 'KeyE',
    N: 'KeyN',
    L: 'KeyL',
    X: 'KeyX',
    RIGHT: 'ArrowRight',
    LEFT: 'ArrowLeft',
    UP: 'ArrowUp',
    DOWN: 'ArrowDown'
};
