import { log } from './logging.service';

export function measure(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
) {
    const originalMethod = descriptor.value;

    descriptor.value = function (...args: any[]) {
        const start = performance.now();
        const result = originalMethod.apply(this, args);
        const end = performance.now();
        log.debug(`${propertyKey} executed in ${end - start} milliseconds`);
        return result;
    };

    return descriptor;
}
