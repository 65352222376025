import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'tm-navigator',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './navigator.component.html',
    styleUrl: './navigator.component.scss'
})
export class NavigatorComponent {}
