import { Component, OnInit } from '@angular/core';
import { StorageFacade } from '@customer/services/storage.facade';
import { Observable } from 'rxjs';
import IMapKey from '@customer/domain/map.interface';
import {
    ApplyMixins,
    Constructor,
    DestroyMixin,
    IDestroyMixin
} from '@customer/mixins';

const mixins = ApplyMixins(class {}, [
    DestroyMixin
]) as Constructor<IDestroyMixin>;

@Component({
    selector: 'tm-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent extends mixins implements OnInit {
    constructor(private storage: StorageFacade) {
        super(arguments);
    }

    localMaps: string[];
    maps: Observable<IMapKey[]>;

    ngOnInit(): void {
        this.localMaps = this.storage.getLocalMapKeys();
        this.maps = this.storage.getRemoteMaps();
    }
}
