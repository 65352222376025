import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ButtonComponent } from './features/buttons/button/button.component';
import { BlockComponent } from './features/canvas/block/block.component';
import { CanvasComponent } from './features/canvas/canvas.component';
import { LineComponent } from './features/canvas/line/line.component';
import { LineDrawComponent } from './features/canvas/line-draw/line-draw.component';
import { ScaleContainerComponent } from './features/canvas/scale-container/scale-container.component';
import { PageNotFoundComponent } from './features/page-not-found/page-not-found.component';
import { taskmapCustomerRoutes } from './routing';
import { ZoomDirective } from './util';

import { DragScaleModule } from '@drag-scale';
import { EditorInputComponent } from './features/editor/editor-input.component';
import { TsJsonSerializerModule } from '../ts-json-serializer';
import { SaveComponent } from './features/dialogs/save/save.component';
import {
    MatDialogModule,
    MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/dialog';
import {
    MatSnackBar,
    MatSnackBarModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS
} from '@angular/material/snack-bar';

import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { NavComponent } from './features/nav/nav.component';
import { NotesComponent } from './features/notes/notes.component';
import { SelectionComponent } from './features/canvas/scale-container/selection.component';
import { DebugPanelComponent } from './features/debug-panel/debug-panel.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { BlockResizerDirective } from './directives/block-resizer.directive';
import { VpUtilModule, log } from '@vp-util';
import { environment } from '../environments/environment';

@NgModule({
    declarations: [
        AppComponent,
        CanvasComponent,
        PageNotFoundComponent,
        ZoomDirective,
        ButtonComponent,
        BlockComponent,
        LineComponent,
        LineDrawComponent,
        ScaleContainerComponent,
        EditorInputComponent,
        SaveComponent,
        NavComponent,
        NotesComponent,
        SelectionComponent,
        DebugPanelComponent,
        BlockResizerDirective
    ],
    imports: [
        RouterModule.forRoot(taskmapCustomerRoutes, {
            enableTracing: false /* <-- debugging purposes only*/,
            useHash: true /* because of GitLab pages. Temporary solution */
        }),
        VpUtilModule.forRoot(environment),
        BrowserModule,
        BrowserAnimationsModule,
        MatSliderModule,
        DragDropModule,
        LayoutModule,
        MatToolbarModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatSidenavModule,
        MatDialogModule,
        HttpClientModule,
        MatInputModule,
        MatIconModule,
        MatListModule,
        MatMenuModule,
        MatSnackBarModule,
        FormsModule,
        DragScaleModule,
        NgxJsonViewerModule,
        TsJsonSerializerModule
    ],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true, panelClass: 'dialogPanel' }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 2500, verticalPosition: 'bottom' }
        },
        log
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private logger: log /* ctor will be called, config loaded */) {}
}
