import { Point } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ElementRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    fromEvent,
    interval,
    merge,
    race,
    Subject
} from 'rxjs';
import {
    delay,
    distinctUntilChanged,
    takeUntil,
    tap,
    throttle,
    throttleTime
} from 'rxjs/operators';
import {
    IBlock,
    IConnectable,
    IConnectableBlock
} from '@customer/domain/block.interface';
import { determineLineDirection, Direction, IPoint } from '@drag-scale';
import { log, measure } from '@vp-util';
import { toDownLine, toLeftLine, toRightLine, toTopLine } from '@customer/util';

const r = (k, x) => /*Math.round(*/ k * x; /*)*/
const arrowDelta = 0; // play with 5px value, but there is issue with dragginh source block or destination block

//type Direction = 'up' | 'left' | 'down' | 'right';
type Arrow = 'back' | 'forward';

@Component({
    selector: 'tm-line',
    templateUrl: './line.component.html',
    styleUrls: ['./line.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineComponent implements OnInit, AfterViewInit {
    constructor(private cdr: ChangeDetectorRef, private zone: NgZone) {}

    dExpr = '';

    S1: Point;
    S2: Point;
    Sm: Point;
    S1Slope: Point;
    S2Slope: Point;
    SmSlope: Point;
    SmSlope2: Point;
    SlopeLine = '';

    @Input()
    debug = false;

    @Input()
    from: IConnectableBlock;

    @Input()
    to: IConnectableBlock;

    @ViewChild('path', { read: ElementRef, static: true })
    path: ElementRef;
    @ViewChild('path_border', { read: ElementRef, static: true })
    path_border: ElementRef;
    @ViewChild('line_group', { read: ElementRef, static: true })
    line_group: ElementRef;

    @Input()
    lineDirection: Direction = 'right';
    @Input()
    arrow: Arrow = 'forward';

    private startBlock: IConnectableBlock;
    private endBlock: IConnectableBlock;
    private lastPath = '';
    $disconnected = new Subject();

    $isSelected = new BehaviorSubject(false);

    ngOnInit(): void {}
    ngAfterViewInit(): void {
        this.connect(this.from, this.to);
        const lineGroup$ = fromEvent(this.line_group.nativeElement, 'click');

        lineGroup$
            .pipe(takeUntil(this.$disconnected))
            .subscribe(this.onLineClick.bind(this));
    }

    onLineClick(event: MouseEvent) {
        this.$isSelected.next(!this.$isSelected.value);
    }

    connect(start: IConnectableBlock, end: IConnectableBlock) {
        if (!start) throw new Error('start block is not specified');
        if (!end) throw new Error('end block is not specified');

        this.startBlock = start;
        this.endBlock = end;

        this.endBlock.$destroy
            .pipe(takeUntil(this.$disconnected))
            .subscribe(() => {
                this.disconnect();
                this.cdr.markForCheck();
            });

        this.zone.runOutsideAngular(() => {
            combineLatest([
                this.startBlock.$position,
                this.startBlock.$width,
                this.startBlock.$height,
                this.endBlock.$position,
                this.endBlock.$width,
                this.endBlock.$height
            ])
                .pipe(takeUntil(this.$disconnected), throttleTime(12)) // to run js next turn
                .subscribe(this.handleLineChanges.bind(this));
        });
    }

    private handleLineChanges([startPos, w1, h1, endPos, w2, h2]) {
        let line = this.buildCurve(startPos, w1, h1, endPos, w2, h2);
        // Update only if the path has changed
        if (line !== this.lastPath) {
            this.lastPath = line;
            this.path.nativeElement.setAttribute('d', line);
            this.path_border.nativeElement.setAttribute('d', line);
            this.zone.run(() => {
                this.cdr.markForCheck();
            });
        }
    }

    private buildCurve(
        startPos: IPoint,
        w1: number,
        h1: number,
        endPos: IPoint,
        w2: number,
        h2: number
    ) {
        let line = '';
        let direction = this.lineDirection;

        if (direction == 'auto') {
            direction = determineLineDirection(
                /* center of parent */
                {
                    x: startPos.x + w1 / 2,
                    y: startPos.y - h1 / 2
                },
                /* center of child */
                { x: endPos.x + w2 / 2, y: endPos.y - h2 / 2 }
            );
        }

        switch (direction) {
            case 'left':
                line = toLeftLine(startPos, w1, h1, endPos, w2, h2);
                break;
            case 'up':
                line = toTopLine(startPos, w1, h1, endPos, w2, h2);
                break;
            case 'down':
                line = toDownLine(startPos, w1, h1, endPos, w2, h2);
                break;
            case 'right':
            default:
                line = toRightLine(startPos, w1, h1, endPos, w2, h2);
                break;
        }
        return line;
    }

    disconnect() {
        this.$disconnected.next();
        this.from.disconnect(this.endBlock);
        //this.from.
    }

    // private toTopLine(
    //     startPos: Point,
    //     w0: number,
    //     h0: number,
    //     endPos: Point,
    //     w1: number,
    //     h1: number
    // ): string {
    //     const w = endPos.x - startPos.x;
    //     const h = startPos.y - endPos.y;
    //     // TODO: add logic to determine line direction at blocks
    //     const halfH0 = r(0.5, h0);
    //     const s1 = { x: 0, y: -halfH0 };
    //     const s2 = { x: w - r(0.5, w0 - w1), y: -r(0.5, h0) - h + h1 };
    //     const vDistance = s2.y - s1.y;
    //     //const hDistance = pos2.x - pos1.x - w1;
    //     const hDistance = s1.x - s2.x;
    //     const s1Slope = { x: s1.x, y: s1.y + r(0.1, vDistance) };
    //     const s2Slope = { x: s2.x, y: s2.y - r(0.1, vDistance) };

    //     const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    //     const smSlope = {
    //         x: sm.x + r(0.4, hDistance),
    //         y: sm.y - r(0.2, vDistance)
    //     };

    //     this.processDebug(s1, s2, sm, s1Slope, s2Slope, smSlope);

    //     const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    //     return line;
    // }
    // private toDownLine(
    //     startPos: Point,
    //     w0: number,
    //     h0: number,
    //     endPos: Point,
    //     w1: number,
    //     h1: number
    // ): string {
    //     const w = endPos.x - startPos.x;
    //     const h = endPos.y - startPos.y;
    //     // TODO: add logic to determine line direction at blocks
    //     const halfH0 = r(0.5, h0);
    //     const s1 = { x: 0, y: halfH0 };
    //     const s2 = { x: w - r(0.5, w0 - w1), y: h - r(0.5, h0) };
    //     const vDistance = s2.y - s1.y;
    //     //const hDistance = pos2.x - pos1.x - w1;
    //     const hDistance = s1.x - s2.x;
    //     const s1Slope = { x: s1.x, y: s1.y + r(0.1, vDistance) };
    //     const s2Slope = { x: s2.x, y: s2.y - r(0.1, vDistance) };

    //     const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    //     const smSlope = {
    //         x: sm.x + r(0.4, hDistance),
    //         y: sm.y - r(0.2, vDistance)
    //     };

    //     this.processDebug(s1, s2, sm, s1Slope, s2Slope, smSlope);

    //     const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    //     return line;
    // }

    // private toLeftLine(
    //     startPos: Point,
    //     w0: number,
    //     h0: number,
    //     endPos: Point,
    //     w1: number,
    //     h1: number
    // ): string {
    //     const w = startPos.x - endPos.x;
    //     const h = startPos.y - endPos.y;
    //     // TODO: add logic to determine line direction at blocks
    //     const halfW0 = r(0.5, w0);
    //     const s1 = { x: -halfW0, y: 0 };
    //     const s2 = { x: -(w - w1 + halfW0), y: -r(1, h0 * 0.5 + h - h1 * 0.5) };
    //     const vDistance = s2.y - s1.y;
    //     //const hDistance = pos2.x - pos1.x - w1;
    //     const hDistance = s1.x - s2.x;
    //     const s1Slope = { x: -(halfW0 + r(0.1, hDistance)), y: 0 };
    //     const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    //     const smSlope = {
    //         x: sm.x + r(0.2, hDistance),
    //         y: sm.y - r(0.4, vDistance)
    //     };
    //     const s2Slope = { x: r(1, -halfW0 - 0.9 * hDistance), y: s2.y };

    //     this.processDebug(s1, s2, sm, s1Slope, s2Slope, smSlope);

    //     const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    //     return line;
    // }
    // private toRightLine(
    //     startPos: Point,
    //     w0: number,
    //     h0: number,
    //     endPos: Point,
    //     w1: number,
    //     h1: number
    // ): string {
    //     // return 'M 0 0, C 25 0, 35 25, 50 50 S 75 100, 100 100';   // sample spline

    //     // normalize distance by difference
    //     // zero point - is center of start block
    //     const w = endPos.x - startPos.x;
    //     const h = endPos.y - startPos.y;
    //     // TODO: add logic to determine line direction at blocks
    //     const halfW0 = r(0.5, w0);
    //     const s1 = { x: halfW0, y: 0 };
    //     const s2 = { x: w - halfW0, y: r(0.5, 2 * h - h0 + h1) };
    //     const vDistance = s2.y - s1.y;
    //     //const hDistance = pos2.x - pos1.x - w1;
    //     const hDistance = s2.x - s1.x;
    //     const s1Slope = { x: halfW0 + r(0.1, hDistance), y: 0 };
    //     const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    //     const smSlope = {
    //         x: sm.x - r(0.2, hDistance),
    //         y: sm.y - r(0.4, vDistance)
    //     };
    //     const s2Slope = { x: r(1, w - halfW0 - hDistance / 10), y: s2.y };

    //     this.processDebug(s1, s2, sm, s1Slope, s2Slope, smSlope);

    //     this.tick = new Date().getTime();

    //     const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    //     // console.log(line);
    //     return line;
    // }

    private processDebug(
        s1: { x: number; y: number },
        s2: { x: number; y: number },
        sm: { x: number; y: number },
        s1Slope: { x: number; y: number },
        s2Slope: { x: number; y: number },
        smSlope: { x: number; y: number }
    ) {
        if (this.debug) {
            // draw debug points and lines
            this.S1 = s1;
            this.S2 = s2;
            this.Sm = sm;
            this.S1Slope = s1Slope;
            this.S2Slope = s2Slope;
            this.SmSlope = smSlope;
            this.SmSlope2 = {
                x: 2 * sm.x - smSlope.x,
                y: 2 * sm.y - smSlope.y
            };
            this.SlopeLine = `M${this.SmSlope2.x} ${this.SmSlope2.y} L ${smSlope.x} ${smSlope.y}`;
        }
    }
}
