import { Injectable } from '@angular/core';
import { IPoint } from '@drag-scale';
import { BehaviorSubject, Subject } from 'rxjs';
import { StoryBlock } from '../domain/story-block';
import { StoryMap } from '../domain/story-map';
import { v4 as uuidv4 } from 'uuid';
import { IBlock } from '../domain/block.interface';
import { Direction, Line, LineDraw } from '../domain';

export const randomPoint = () => ({
    x: Math.random() * 1400,
    y: Math.random() * 900
});

export type BrokerMode =
    | 'normal'
    | 'DrawLine-Step1'
    | 'DrawLine-Step2'
    | undefined;

export const getChildrenRect = (childs: IBlock[]) => {
    let minX = Number.POSITIVE_INFINITY;
    let minY = Number.POSITIVE_INFINITY;
    let maxX = Number.NEGATIVE_INFINITY;
    let maxY = Number.NEGATIVE_INFINITY;
    childs.forEach(i => {
        const { x, y } = i.$position.value;
        minX = Math.min(minX, x);
        minY = Math.min(minY, y);
        maxX = Math.max(maxX, x + i.$width.value);
        maxY = Math.max(maxY, y + i.$height.value);
    });
    return { x: minX, y: minY, x1: maxX, y1: maxY };
};

@Injectable({
    providedIn: 'root'
})
export class BlockBrokerService {
    $storyMap = new BehaviorSubject(new StoryMap());
    $saveMap = new Subject<string>();
    $newMap = new Subject();
    $mapLoaded = new Subject<string>();
    $showNote = new Subject<StoryBlock>();
    $showDebug = new Subject<StoryBlock>();
    $selectedBlocks = new Subject<StoryBlock[]>();
    $mode = new BehaviorSubject<BrokerMode>('normal');

    $isSelectionActive = new BehaviorSubject(false);

    private blockHash = new Map<string, StoryBlock>();
    private selected: StoryBlock[] = [];

    constructor() {}

    changePosition(block: StoryBlock, position: IPoint) {
        block.$position.next(position);
    }

    addSibling(block: StoryBlock) {
        if (block.parent) {
            let parentLine = block.parent.outgoingLines.find(
                l => l.destination === block
            );
            this.addChildBlock(block.parent, parentLine.direction);
        }
    }

    drawLine() {
        // Case 1: nothing selected
        // set mode to select Some item
        //  - wait when something will be selected
        if (this.selected.length == 0) {
            this.$mode.next('DrawLine-Step1');
            return;
        }

        // Case 2: something selected
        // Add LineDraw
        this.$mode.next('DrawLine-Step2');
        this.selected.forEach(block => {
            block.outgoingLines.push(new LineDraw()); // should draw connector line
        });
    }

    saveMap(mapName: string) {
        this.$saveMap.next(mapName);
    }
    newMap() {
        this.$newMap.next();
        this.$mapLoaded.next();
    }

    mapLoaded(mapName: string) {
        this.$mapLoaded.next(mapName);
    }

    showNote(blockModel: StoryBlock) {
        this.$showNote.next(blockModel);
    }
    showDebug(blockModel: StoryBlock) {
        this.$showDebug.next(blockModel);
    }

    load(map: StoryMap) {
        this.clearAll();
        this.blockHash.clear();
        map.blocks.forEach(sb => this.blockHash.set(sb.id, sb));
        this.$storyMap.next(map);
    }

    clearAll() {
        this.$storyMap.next(new StoryMap());
        this.blockHash.clear();
    }

    selectBlocks(ids: string[]) {
        //different modes are possible on selection

        //Case 1: select destination on Line connection
        if (this.$mode.value == 'DrawLine-Step2') {
            const dstBlocks = ids
                .map(id => this.blockHash.get(id))
                .filter(x => !!x);
            //remove drawing line
            //add real line
            this.selected.forEach(srcB =>
                dstBlocks.forEach(dstB => {
                    // Remove draw line
                    srcB.outgoingLines = srcB.outgoingLines.filter(
                        line => !(line instanceof LineDraw)
                    );
                    // Push the new line
                    srcB.outgoingLines.push(new Line(dstB, 'auto'));
                })
            );
            this.deselectAll();
            this.$mode.next('normal');
            return;
        }

        //Case N: regular select
        const newSelectedSet = new Set(this.selected.map(block => block.id));
        ids.forEach(id => {
            if (!newSelectedSet.has(id) && this.blockHash.has(id)) {
                const b = this.blockHash.get(id);
                b.$selected.next(true);
                this.selected.push(b);
                newSelectedSet.add(id);
            }
        });
    }

    deselectBlocks(ids: string[]) {
        const toRemoveSet = new Set(ids);
        this.selected = this.selected.filter(item => {
            if (toRemoveSet.has(item.id) && item.$selected.value) {
                item.$selected.next(false);
                return false;
            }
            return item.$selected.value;
        });

        // Update blockHash items that are not in selected
        toRemoveSet.forEach(id => {
            const b = this.blockHash.get(id);
            if (b && b.$selected.value) {
                b.$selected.next(false);
            }
        });
    }
    deselectAll() {
        this.selected = [];
        this.blockHash.forEach(b => b.$selected.next(false));
    }

    addBlock(
        text: string = '',
        position: IPoint = randomPoint(),
        editMode = false
    ): StoryBlock {
        let block = new StoryBlock(text, position, uuidv4());
        block.$selected.next(editMode);
        block.$editMode.next(editMode);
        this.$storyMap.value.blocks.push(block);
        this.blockHash.set(block.id, block);
        return block;
    }
    removeBlock(block: StoryBlock) {
        const i = this.$storyMap.value.blocks.indexOf(block);
        if (i >= 0) {
            this.deselectBlocks([block.id]);
            this.$storyMap.value.blocks.splice(i, 1);
            block.$destroy.next();
            this.blockHash.delete(block.id);
        }
    }
    addChildBlock(parent: StoryBlock, direction: Direction) {
        if (!parent) throw new Error('Parent is not specified');

        let pos: IPoint;
        switch (direction) {
            case 'left': {
                pos = this.getPositionForChildLeft(parent);
                break;
            }
            case 'up': {
                pos = this.getPositionForChildTop(parent);
                break;
            }
            case 'down': {
                pos = this.getPositionForChildDown(parent);
                break;
            }
            default: {
                pos = this.getPositionForChildRight(parent);
                break;
            }
        }

        let child = new StoryBlock('', pos, uuidv4());
        child.parent = parent;
        this.blockHash.set(child.id, child);
        this.$storyMap.value.blocks.push(child);
        child.$selected.next(true);
        child.$editMode.next(true);

        parent.outgoingLines.push(new Line(child, direction)); // should draw connector line

        // test to mark for changes
        parent.$selected.next(false);
        parent.$editMode.next(false);
    }

    extractSelectedToNavBlock() {
        // create nav block
        let pos = getChildrenRect(this.selected);
        pos.x = (pos.x + pos.x1) * 0.5;
        pos.y = (pos.y + pos.y1) * 0.5;
        let nav = this.addBlock('Навигация', pos);

        // link all parents of selected to created nav block
        let parentProcessedIds = new Set<string>();
        this.selected.forEach(b => {
            if (b.parent && !parentProcessedIds.has(b.parent.id)) {
                b.parent.outgoingLines.push(new Line(nav, 'down'));
                parentProcessedIds.add(b.parent.id);
            }
            this.removeBlock(b);
        });
    }

    private getPositionForChildLeft(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x -= 200;
        pos.y -= 10;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'left');

        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x;
        pos.y = rect.y1 + 10;
        return pos;
    }
    private getPositionForChildTop(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x -= 10;
        pos.y -= 100;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'up');

        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x1 + 30;
        pos.y = rect.y;

        return pos;
    }
    private getPositionForChildDown(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x -= 10;
        pos.y += 150 + parent.$height.value;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'down');

        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x1 + 30;
        pos.y = rect.y;

        return pos;
    }
    private getPositionForChildRight(parent: StoryBlock): IPoint {
        const pos = { ...parent.$position.value };
        pos.x += 150 + parent.$width.value;
        pos.y -= 50;

        const blocks = this.getDirectionChilds(parent.outgoingLines, 'right');
        if (0 == blocks.length) return pos;

        const rect = getChildrenRect(blocks);
        pos.x = rect.x + 0;
        pos.y = rect.y1 + 10;

        return pos;
    }

    private getDirectionChilds(lines: Line[], direction: Direction) {
        const blocks = [];
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].direction === direction)
                blocks.push(lines[i].destination);
        }
        return blocks;
    }
}
