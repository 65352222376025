import { Subject } from 'rxjs';
import { JsonProperty, Serializable } from '../../ts-json-serializer';

@Serializable()
export class NodeText {
    @JsonProperty()
    lines:string[];
    cursor = new cursorPointer();
    selection: any;

    constructor(text = '') {
        this.lines = [text];
    }

    toString(): string {
        let ret = '';
        ret = this.lines.reduce((p, c, i) => {
            return p + '\r\n' + c;
        });
        return ret;
    }
    addLine() {
        this.lines.push('');
        this.cursor.line++;
        this.cursor.position = 0;
    }
    addSymbol(str: string) {
        this.lines[this.cursor.line] += str;
        this.cursor.position += str.length;
    }
    /// left from cursor
    removeSymbolLeft() {
        let curLine = this.cursor.line;
        if (this.cursor.position == 0 && curLine > 0) {
            let line = this.lines[curLine - 1] + this.lines[curLine];
            this.cursor.position = this.lines[curLine - 1].length;
            this.lines[curLine - 1] = line;
            this.lines.splice(curLine, 1);
        } else {
            let line = this.lines[curLine];
            this.lines[curLine] =
                line.substring(0, this.cursor.position - 1) +
                line.substring(this.cursor.position);
            this.cursor.position--;
        }
    }
    /// right from cursor
    removeSymbolRight() {
        let line = this.lines[this.cursor.line];
        this.lines[this.cursor.line] =
            line.substring(0, this.cursor.position) +
            line.substring(this.cursor.position + 1);
    }
    cursorLeft() {
        if (this.cursor.position > 0) this.cursor.position--;
    }
    cursorRight() {
        if (this.cursor.position < this.lines[this.cursor.line].length)
            this.cursor.position++;
    }
    private correctPosition() {
        this.cursor.position =
            this.lines[this.cursor.line].length < this.cursor.position
                ? this.lines[this.cursor.line].length
                : this.cursor.position;
    }
    cursorUp() {
        if (this.cursor.line > 0) {
            this.cursor.line--;
            this.correctPosition();
        }
    }
    cursorDown() {
        if (this.cursor.line < this.lines.length) {
            this.cursor.line++;
            this.correctPosition();
        }
    }

    static fromString(line: string): NodeText {
        let n = new NodeText();
        n.addSymbol(line);
        return n;
    }
}

class cursorPointer {
    line: number = 0;
    position: number = 0;
}
