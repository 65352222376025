import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export var registerIcons = (
    matIconRegistry: MatIconRegistry,
    domSanitizer: DomSanitizer
) => {
    matIconRegistry.addSvgIcon(
        'logo',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/logo.svg')
    );
    matIconRegistry.addSvgIcon(
        'new',
        domSanitizer.bypassSecurityTrustResourceUrl('assets/new.svg')
    );
};
