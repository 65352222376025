import { coerceElement } from '@angular/cdk/coercion';
import { DragDropRegistry, DropListRef } from '@angular/cdk/drag-drop';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { ElementRef, NgZone } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil, takeWhile } from 'rxjs/operators';
import { DragScaleService } from './services/drag-scale.service';
import { DragScaleSettingsProvider, IPoint, IRect } from './structures';
import { TransformState } from './transform-state';
import { TransformableConfig } from './transformable';
import {
    activeEventListenerOptions,
    debugPoint,
    getMoveTransform,
    getScaleTransform,
    isTouchEvent,
    MOUSE_EVENT_IGNORE_TIME,
    MoveSelectionService,
    passiveEventListenerOptions,
    scaleRectangle
} from '@drag-scale';

export class TransformableRef {
    constructor(
        element: ElementRef<HTMLElement>,
        eventSource: ElementRef<HTMLElement>,
        private config: TransformableConfig,
        private _ngZone: NgZone,
        private _viewportRuler: ViewportRuler,
        private _registry: DragDropRegistry<TransformableRef, DropListRef>,
        private scaleService: DragScaleService,
        private moveSelectionService: MoveSelectionService,
        private _container: TransformableRef = null,
        settingsProvider: DragScaleSettingsProvider
    ) {
        this.init(element, eventSource);
        settingsProvider.$settings
            .pipe(takeUntil(this.$destroy))
            .subscribe(s => {
                this.isTouchPad = s.isTouchPad;
            });
    }

    private isTouchPad = false;
    private $destroy = new Subject();

    /* transforming element - what is moving i.g. tm-scale-container */
    private _rootElement: HTMLElement;
    /* eventing element - what is raising events, container .blocks */
    private _eventElement: HTMLElement;
    // our transform state
    private _transformState = new TransformState(1, { x: 0, y: 0 });

    /**
     * Amount of milliseconds to wait after the user has put their
     * pointer down before starting to drag the element.
     */
    dragStartDelay: number | { touch: number; mouse: number } = 0;

    /**
     * Whether the dragging sequence has been started. Doesn't
     * necessarily mean that the element has been moved.
     */
    private _hasStartedDragging: boolean;

    /**
     * Time at which the last touch event occurred. Used to avoid firing the same
     * events multiple times on touch devices where the browser will fire a fake
     * mouse event for each touch event, after a certain time.
     */
    private _lastTouchEventTime: number;

    /** Subscription to pointer movement events. */
    private _pointerMoveSubscription = Subscription.EMPTY;

    /** Subscription to the event that is dispatched when the user lifts their pointer. */
    private _pointerUpSubscription = Subscription.EMPTY;

    /** Subscription to the viewport being scrolled. */
    private _scrollSubscription = Subscription.EMPTY;

    disabled = false;
    /** Cached scroll position on the page when the element was scaled. */
    private _scrollPosition: { top: number; left: number };

    private _pickupPositionOnPage: IPoint;
    private _pickupTransformPosition: IPoint;

    /** Time at which the last dragging sequence was started. */
    private _dragStartTime: number;

    beforeScale = new Subject<void>();
    afterScale = new Subject<number>();
    beforeDrag = new Subject<MouseEvent | TouchEvent>();
    afterDrag = new Subject<void>();

    $transformState = new BehaviorSubject<TransformState>(this._transformState);

    init(
        rootElement: ElementRef<HTMLElement>,
        eventSource: ElementRef<HTMLElement> = null
    ): this {
        const element = coerceElement(rootElement);
        const eventElement = eventSource ? coerceElement(eventSource) : element;

        // run all event listeners outside angular
        this._ngZone.runOutsideAngular(() => {
            if (element !== this._rootElement) {
                if (this._rootElement) {
                    this.removeRootElementListeners(this._eventElement);
                }

                // bind events
                if (this.config.scaleOnWheelEnabled) {
                    eventElement.addEventListener(
                        'wheel',
                        this.wheelHandler,
                        activeEventListenerOptions
                    );
                }

                eventElement.addEventListener(
                    'mousedown',
                    this.pointerDown,
                    activeEventListenerOptions
                );
                eventElement.addEventListener(
                    'touchstart',
                    this.pointerDown,
                    passiveEventListenerOptions
                );
                this._rootElement = element;
                this._eventElement = eventElement;
            }
        });

        this._scrollPosition = this._viewportRuler.getViewportScrollPosition();
        return this;
    }

    /** Removes the manually-added event listeners from the root element. */
    private removeRootElementListeners(element: HTMLElement) {
        element.removeEventListener(
            'mousedown',
            this.pointerDown,
            activeEventListenerOptions
        );
        element.removeEventListener(
            'touchstart',
            this.pointerDown,
            passiveEventListenerOptions
        );
        if (this.config.scaleOnWheelEnabled) {
            element.removeEventListener(
                'wheel',
                this.wheelHandler,
                activeEventListenerOptions
            );
        }
    }

    /****** EVENT HANDLERS *******/

    private wheelHandler = (event: WheelEvent) => {
        if (this.disabled) {
            return;
        }
        this.beforeScale.next();

        if (this.isTouchPad && !event.ctrlKey) {
            this.touchMove(event);
            return;
        }
        if (event.deltaY !== 0) {
            this.scale(this._rootElement, event);
        }
    };

    /** Handler for the `mousedown`/`touchstart` events. */
    private pointerDown = (event: MouseEvent | TouchEvent) => {
        if (this.disabled) {
            // self-captured - disable for world (for case of edit active)
            event.stopPropagation();
            return;
        }

        // skip shift down - it's a selection
        if (event.shiftKey) {
            return;
        }

        this.beforeDrag.next(event);
        this.initializeDragSequence(this._rootElement, event);
    };

    private pointerMove = (event: MouseEvent | TouchEvent) => {
        // Prevent the default action as early as possible in order to block
        // native actions like dragging the selected text or images with the mouse.
        event.preventDefault();
        const pointerPosition = this.getPointerPositionOnPage(event);

        if (!this._hasStartedDragging) {
            const distanceX = Math.abs(
                pointerPosition.x - this._pickupPositionOnPage.x
            );
            const distanceY = Math.abs(
                pointerPosition.y - this._pickupPositionOnPage.y
            );
            const isOverThreshold =
                distanceX + distanceY >= this.config.dragStartThreshold;

            // Only start dragging after the user has moved more than the minimum distance in either
            // direction. Note that this is preferrable over doing something like `skip(minimumDistance)`
            // in the `pointerMove` subscription, because we're not guaranteed to have one move event
            // per pixel of movement (e.g. if the user moves their pointer quickly).
            if (isOverThreshold) {
                const isDelayElapsed =
                    Date.now() >=
                    this._dragStartTime + this.getDragStartDelay(event);
                if (!isDelayElapsed) {
                    this.endDragSequence(event);
                    return;
                }

                // Prevent other drag sequences from starting while something in the container is still
                // being dragged. This can happen while we're waiting for the drop animation to finish
                // and can cause errors, because some elements might still be moving around.
                this._hasStartedDragging = true;
                this._ngZone.run(() => this.startDragSequence(event));
            }
            return;
        }

        // calculate transformations (relative to pickup position)
        let deltaX = pointerPosition.x - this._pickupPositionOnPage.x;
        let deltaY = pointerPosition.y - this._pickupPositionOnPage.y;

        // if we have scaled container, then use it's scale to correct position
        if (this._container && this._container._transformState.scale !== 1) {
            const scaleFrac =
                this._transformState.scale /
                this._container._transformState.scale;
            deltaX *= scaleFrac;
            deltaY *= scaleFrac;
        }

        // also need to normolize delta coordinates
        // delta is active durin moving - no use += here
        this._transformState.position.x = Math.round(
            this._pickupTransformPosition.x + deltaX
        );
        this._transformState.position.y = Math.round(
            this._pickupTransformPosition.y + deltaY
        );

        this.applyRootElementTransform(
            this._transformState.scale,
            this._transformState.position
        );

        // this._ngZone.runOutsideAngular(() => {
        //     setTimeout(() => {
        //         // update component data
        //         // but don't trigger change detection.
        //         this.$transformState.next(this._transformState);
        //     });
        // });

        this.$transformState.next(this._transformState);
        this.moveSelectionService.$deltaMove.next({ x: deltaX, y: deltaY });

        // Use carefully - possible low performance
        //this._ngZone.run(x => x++);
    };

    private pointerUp = (event: MouseEvent | TouchEvent) => {
        this.endDragSequence(event);
    };
    /*********************************/

    private scale(referenceElement: HTMLElement, event: WheelEvent) {
        event.stopPropagation();
        event.preventDefault();
        this._scrollPosition = this._viewportRuler.getViewportScrollPosition();
        const sc0 = this._transformState.scale;
        let sc1 = sc0; // new scale
        if (this.isTouchPad) {
            sc1 = sc0 - event.deltaY / 100;
        } else {
            sc1 = sc0 - event.deltaY / 1000;
        }
        if (sc1 < 0)
            // do not scale
            return;
        // recalc position for scale
        const pointerPosition = this.getPointerPositionOnPage(event);
        const currentPosition = this._transformState.position;
        const newPoint = scaleRectangle(
            currentPosition,
            pointerPosition,
            sc1 / sc0
        );
        this._transformState.scale = sc1;
        this._transformState.position = newPoint;
        this.applyRootElementTransform(
            this._transformState.scale,
            this._transformState.position
        );

        // TBD: Raise own event except calling external service member
        this.scaleService.$scaleFactor.next(this._transformState.scale * 100);

        this.$transformState.next(this._transformState);
    }

    private touchMove(event: WheelEvent) {
        this._rootElement.classList.add('moving');
        // addition init
        this._scrollPosition = this._viewportRuler.getViewportScrollPosition();

        //this.initializeDragSequence(this._rootElement, event);
        let pointerPosition = (this._pickupPositionOnPage =
            this.getPointerPositionOnPage(event));

        this._pickupTransformPosition = { x: 0, y: 0 };
        this._pickupTransformPosition.x = this._transformState.position.x;
        this._pickupTransformPosition.y = this._transformState.position.y;
        this._dragStartTime = Date.now();
        this._registry.startDragging(this, event);

        // ================  touch move logic should be here =================================
        event.preventDefault();
        pointerPosition = this.getPointerPositionOnPage(event);

        if (!this._hasStartedDragging) {
            const distanceX = event.deltaX;
            const distanceY = event.deltaY;
            const isOverThreshold =
                distanceX + distanceY >= this.config.dragStartThreshold;
            if (isOverThreshold) {
                this._hasStartedDragging = true;
            }
        }
        // calculate transformations
        let deltaX = -event.deltaX * 1.5;
        let deltaY = -event.deltaY * 1.5;

        // if we have scaled container, then use it's scale to correct position
        if (this._container && this._container._transformState.scale !== 1) {
            const scaleFrac =
                this._transformState.scale /
                this._container._transformState.scale;
            deltaX *= scaleFrac;
            deltaY *= scaleFrac;
        }

        // also need to normalize delta coordinates
        // delta is active durin moving - no use += here
        this._transformState.position.x = Math.round(
            this._pickupTransformPosition.x + deltaX
        );
        this._transformState.position.y = Math.round(
            this._pickupTransformPosition.y + deltaY
        );

        this.applyRootElementTransform(
            this._transformState.scale,
            this._transformState.position
        );

        this.$transformState.next(this._transformState);

        // Use carefully - possible low performance
        //this._ngZone.run(x => x++);

        this.endDragSequence(event);
    }

    /**
     * Sets up the different variables and subscriptions
     * that will be necessary for the dragging sequence.
     * @param referenceElement Element that started the drag sequence.
     * @param event Browser event object that started the sequence.
     */
    private initializeDragSequence(
        referenceElement: HTMLElement,
        event: MouseEvent | TouchEvent
    ) {
        // Always stop propagation for the event that initializes
        // the dragging sequence, in order to prevent it from potentially
        // starting another sequence for a draggable parent somewhere up the DOM tree.
        event.stopPropagation();

        const isDragging = this.isDragging();
        const isTouchSequence = isTouchEvent(event);
        const isAuxiliaryMouseButton =
            !isTouchSequence && (event as MouseEvent).button !== 0;
        const isSyntheticEvent =
            !isTouchSequence &&
            this._lastTouchEventTime &&
            this._lastTouchEventTime + MOUSE_EVENT_IGNORE_TIME > Date.now();

        // If the event started from an element with the native HTML drag&drop, it'll interfere
        // with our own dragging (e.g. `img` tags do it by default). Prevent the default action
        // to stop it from happening. Note that preventing on `dragstart` also seems to work, but
        // it's flaky and it fails if the user drags it away quickly. Also note that we only want
        // to do this for `mousedown` since doing the same for `touchstart` will stop any `click`
        // events from firing on touch devices.
        if (
            event.target &&
            (event.target as HTMLElement).draggable &&
            event.type === 'mousedown'
        ) {
            event.preventDefault();
        }

        // Abort if the user is already dragging or is using a mouse button other than the primary one.
        if (isDragging || isAuxiliaryMouseButton || isSyntheticEvent) {
            return;
        }

        this._hasStartedDragging = false;

        // Avoid multiple subscriptions and memory leaks when multi touch
        // (isDragging check above isn't enough because of possible temporal and/or dimensional delays)
        this.removeMovingSubscriptions();
        this._pointerMoveSubscription = this._registry.pointerMove.subscribe(
            this.pointerMove
        );
        this._pointerUpSubscription = this._registry.pointerUp.subscribe(
            this.pointerUp
        );
        this._scrollSubscription = this._registry.scroll
            .pipe(startWith(null))
            .subscribe(() => {
                this._scrollPosition =
                    this._viewportRuler.getViewportScrollPosition();
            });

        // If we have a custom preview we can't know ahead of time how large it'll be so we position
        // it next to the cursor. The exception is when the consumer has opted into making the preview
        // the same size as the root element, in which case we do know the size.
        this._pickupPositionOnPage = this.getPointerPositionOnPage(event);

        this._pickupTransformPosition = { ...this._transformState.position };
        this._dragStartTime = Date.now();
        this._registry.startDragging(this, event);

        this._rootElement.classList.add('moving');
    }
    getPointerPositionOnPage(event: MouseEvent | TouchEvent): IPoint {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = isTouchEvent(event)
            ? event.touches[0] || event.changedTouches[0]
            : event;

        return {
            x: point.pageX - this._scrollPosition.left,
            y: point.pageY - this._scrollPosition.top
        };
    }

    private applyRootElementTransform(scale: number, pos: IPoint) {
        let transform = '';
        if (pos) {
            transform += getMoveTransform(pos);
        }
        if (scale) {
            transform += ' ' + getScaleTransform(scale);
        }
        this._rootElement.style.transformOrigin = `0px 0px`;
        this._rootElement.style.transformBox = 'border-box';
        this._rootElement.style.transform = transform;
    }

    /** Checks whether the element is currently being dragged. */
    isDragging(): boolean {
        return this._hasStartedDragging && this._registry.isDragging(this);
    }

    /** Starts the dragging sequence. */
    private startDragSequence(event: MouseEvent | TouchEvent) {
        if (isTouchEvent(event)) {
            this._lastTouchEventTime = Date.now();
        }
    }

    /** Gets the drag start delay, based on the event type. */
    private getDragStartDelay(event: MouseEvent | TouchEvent): number {
        const value = this.dragStartDelay;

        if (typeof value === 'number') {
            return value;
        } else if (isTouchEvent(event)) {
            return value.touch;
        }

        return value ? value.mouse : 0;
    }

    /**
     * Clears subscriptions and stops the dragging sequence.
     * @param event Browser event object that ended the sequence.
     */
    private endDragSequence(event: MouseEvent | TouchEvent) {
        // Note that here we use `isDragging` from the service, rather than from `this`.
        // The difference is that the one from the service reflects whether a dragging sequence
        // has been initiated, whereas the one on `this` includes whether the user has passed
        // the minimum dragging threshold.
        if (!this._registry.isDragging(this)) {
            return;
        }
        this.afterDrag.next();

        this.removeMovingSubscriptions();
        this._registry.stopDragging(this);

        this._rootElement.classList.remove('moving');

        if (!this._hasStartedDragging) {
            return;
        }
        this._registry.stopDragging(this);
        this._ngZone.run(x => x++);
    }

    /** Unsubscribes from the global subscriptions. */
    private removeMovingSubscriptions() {
        this._pointerMoveSubscription.unsubscribe();
        this._pointerUpSubscription.unsubscribe();
        this._scrollSubscription.unsubscribe();
    }

    setPosition(point: IPoint) {
        this._transformState.position.x = point.x;
        this._transformState.position.y = point.y;
        this.applyRootElementTransform(
            this._transformState.scale,
            this._transformState.position
        );
        this.$transformState.next(this._transformState);
    }

    getBoundingClientRect(): IRect {
        return this._eventElement.getBoundingClientRect();
    }

    destroy() {
        this.$destroy.next();
    }
}
