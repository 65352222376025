import { Injectable } from '@angular/core';
import { IPoint, IRect, TransformableRef, TransformState } from '@drag-scale';
import { BehaviorSubject } from 'rxjs';
import { log } from '@vp-util';

@Injectable({
    providedIn: 'root'
})
export class CoordinateService {
    private dragWorld: TransformableRef;
    private $worldTransformState: BehaviorSubject<TransformState>;
    dragWorldRect: IRect;

    constructor() {}

    registerDragWorld(world: TransformableRef) {
        this.dragWorld = world;
        this.$worldTransformState = this.dragWorld.$transformState;
        this.dragWorldRect = this.dragWorld.getBoundingClientRect();
    }

    getPointerPosition(ev: MouseEvent): IPoint {
        let p = this.dragWorld.getPointerPositionOnPage(ev);
        p.x -=
            this.$worldTransformState.value.position.x + this.dragWorldRect.x;
        p.y -=
            this.$worldTransformState.value.position.y + this.dragWorldRect.y;

        // take into account scale
        p.x /= this.$worldTransformState.value.scale;
        p.y /= this.$worldTransformState.value.scale;

        return p;
    }
}
