import { DOCUMENT } from '@angular/common';
const ResizeObserver = window.ResizeObserver; // || Polyfill;

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Inject,
    Injector,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { BlockBrokerService, GlobalSettingsService } from '@customer/services';
import {
    activeEventListenerOptions,
    IPoint,
    MoveSelectionService,
    Transformable,
    TransformableRef
} from '@drag-scale';
import {
    BehaviorSubject,
    combineLatest,
    fromEvent,
    Observable,
    Subject
} from 'rxjs';
import {
    buffer,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    map,
    switchMapTo,
    takeUntil,
    tap
} from 'rxjs/operators';
import { EditableMixin } from '@customer/mixins';
import { StoryBlock } from '@customer/domain/story-block';
import { BLOCK_KEY_BINDING } from './key.binding';
import { invertDirection, Line, LineDraw } from '@customer/domain';
import { BlockResizerDirective } from '@customer/directives/block-resizer.directive';

import { log } from '@vp-util';

@Component({
    selector: 'tm-block',
    templateUrl: './block.component.html',
    styleUrls: ['./block.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockComponent
    extends EditableMixin()
    implements AfterViewInit, OnInit, OnDestroy
{
    private static keyBindings = BLOCK_KEY_BINDING;

    @Input()
    declare textModel: string;

    @Input('block')
    blockModel: StoryBlock;

    @HostBinding('id')
    _id: string;

    @Input()
    dragContainer: TransformableRef;

    private blockTransformable: TransformableRef;
    $destroy = new Subject<void>();
    $width: Observable<string>;
    $selectableModeActive = new BehaviorSubject<boolean>(false);

    isEditMode = false;
    isSelected = false;
    isAreaSelectionActive = false;
    hasCustomSize = false;

    @ViewChild('label', { read: ElementRef, static: true })
    label: ElementRef;
    @ViewChild('block', { read: ElementRef, static: true })
    block: ElementRef;
    @ViewChild(BlockResizerDirective)
    resizerDirective: BlockResizerDirective;

    @ViewChild('editor', { read: ViewContainerRef, static: true })
    declare editorVcr: ViewContainerRef;

    documentClickWrapper = null;
    documentKeyDownWrapper = null;
    windowResizeObserver: ResizeObserver;
    moveSubscription = null;
    private positionOnSelect: IPoint = null;

    constructor(
        private blockElRef: ElementRef<HTMLElement>,
        private ngZone: NgZone,
        private transformable: Transformable,
        @Inject(DOCUMENT) private _document: Document,
        public blockBroker: BlockBrokerService,
        private settingsProvider: GlobalSettingsService,
        public injector: Injector /* needs for mixins */,
        private cdr: ChangeDetectorRef,
        private moveSelectionService: MoveSelectionService
    ) {
        super(arguments);
        this.documentClickWrapper = this.documentClickHandler.bind(this);
        this.documentKeyDownWrapper = this.documentKeyDownHandler.bind(this);
        this.blockBroker.$mode
            .pipe(
                takeUntil(this.$destroy),
                tap(mode => {
                    this.$selectableModeActive.next(
                        mode === 'DrawLine-Step1' || mode === 'DrawLine-Step2'
                    );
                    this.cdr.markForCheck();
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.windowResizeObserver.disconnect();
        this.blockModel.$destroy.next(); // destroy model node and all related connections
        this.blockModel.$destroy.complete(); // destroy model node and all related connections
        this.$destroy.next();
        this.$destroy.complete();
    }

    ngOnInit(): void {
        this.textModel = this.blockModel.text;
        this._id = this.blockModel.id;
        // this.$width = this.blockModel.$width.pipe(
        //     takeUntil(this.$destroy),
        //     filter(x => !!x),
        //     distinctUntilChanged(),
        //     map(width => `${width}px`)
        // );
    }

    ngAfterViewInit(): void {
        this.blockTransformable = this.transformable.createTransformableNoScale(
            this.blockElRef,
            this.settingsProvider,
            this.dragContainer
        );
        this.blockTransformable.setPosition(this.blockModel.$position.value);

        this.$width = this.blockModel.$width.pipe(
            takeUntil(this.$destroy),
            filter(x => !!x),
            debounceTime(12),
            distinctUntilChanged(),
            map(width => `${width}px`)
        );
        this.$width.subscribe(() => {
            this.cdr.markForCheck();
        });

        // single click on block
        fromEvent(this.block.nativeElement, 'click')
            .pipe(takeUntil(this.$destroy), debounceTime(300))
            .subscribe(this.onClick.bind(this));

        // db click on block
        fromEvent(this.block.nativeElement, 'dblclick')
            .pipe(takeUntil(this.$destroy))
            .subscribe(this.onDblClick.bind(this));

        // Hack to invert outgoing lines on moving block
        this.blockTransformable.beforeDrag
            .pipe(takeUntil(this.$destroy))
            .subscribe(this.beforeDrag.bind(this));
        this.blockTransformable.afterDrag
            .pipe(takeUntil(this.$destroy))
            .subscribe(this.afterDrag.bind(this));

        this.blockTransformable.$transformState
            .pipe(takeUntil(this.$destroy))
            .subscribe(ts => {
                // !! call broker service for any model changes
                this.blockBroker.changePosition(this.blockModel, ts.position);
            });

        this.blockModel.$editMode
            .pipe(
                takeUntil(this.$destroy),
                delay(0)
            ) /* play with removing delay(0). that my speed up code but change execution order */
            .subscribe(this.setEditMode.bind(this));

        this.blockModel.$selected
            .pipe(
                takeUntil(this.$destroy),
                debounceTime(50),
                distinctUntilChanged()
            )
            .subscribe(this.onSelectionChange.bind(this));

        // this.blockModel.outgoingLines
        //     .pipe(takeUntil(this.$destroy))
        //     .subscribe(this.onSelectionChange.bind(this));

        // observe block size changes in case of autosize mode
        this.windowResizeObserver = new ResizeObserver(() => this.onResize());
        this.windowResizeObserver.observe(this.blockElRef.nativeElement);
        this.onResize();
    }

    private onSelectionChange(selectionArg: boolean) {
        selectionArg ? this.ProceedSelected() : this.ProceedDeselected();
        this.cdr.markForCheck();
    }

    private onDblClick(ev: MouseEvent) {
        ev.stopPropagation();
        this.setEditMode(true);
    }
    private onClick(ev: MouseEvent) {
        if (this.$selectableModeActive.value) {
            this.blockBroker.selectBlocks([this.blockModel.id]);
            return;
        }
        this.blockBroker.selectBlocks([this.blockModel.id]);
    }
    private documentClickHandler(ev: MouseEvent) {
        // skip clicks when area selection is active
        //if (this.blockBroker.$isSelectionActive.value) return;

        if (
            !this.isBlockInsideClick(ev) &&
            this.blockBroker.$mode.value === 'normal'
        ) {
            this.blockBroker.deselectBlocks([this.blockModel.id]);
        }
    }
    private onResize() {
        // required for inital width on first resizing for new block
        if (this.blockModel.$autoWidth.value) {
            this.resizerDirective.currentWidth =
                this.blockElRef.nativeElement.clientWidth;
            // update width for correct line positioning
        }
        // requiered for lines correct positioning
        //TODO: [WA-22] Extract block changes into broker service
        this.blockModel.$width.next(this.blockElRef.nativeElement.clientWidth);
        this.blockModel.$height.next(
            this.blockElRef.nativeElement.clientHeight
        );
    }

    private beforeDrag(ev: MouseEvent | TouchEvent) {
        // skip block draging if user tries to click e.g. line instead of block
        if (!this.isBlockInsideClick(ev)) return;

        // This need to avoid blocks dragging that may be selected at the moment
        // when user starts dragging some another block that was not selected before
        if (!this.isSelected && this.blockBroker.$mode.value === 'normal') {
            this.blockBroker.deselectAll();
            this.blockBroker.selectBlocks([this.blockModel.id]);
        }

        this.blockModel.$isMoving.next(true);
        // push outgoig lines to destinations
        this.blockModel.outgoingLines.forEach((line: Line) => {
            (line.destination as StoryBlock).$movingBackLines.next([
                new Line(this.blockModel, invertDirection(line.direction))
            ]);
        });
    }
    private afterDrag() {
        this.blockModel.$isMoving.next(false);
        // clear movings in destinations
        this.blockModel.outgoingLines.forEach((line: Line) => {
            (line.destination as StoryBlock).$movingBackLines.next([]);
        });
    }

    isDrawLine(line: Line): boolean {
        return line instanceof LineDraw;
    }

    private ProceedSelected(): void {
        if (!this.isSelected) {
            // bind global click to make unselect possible
            this._document.body.addEventListener(
                'click',
                this.documentClickWrapper,
                activeEventListenerOptions
            );
            this._document.body.addEventListener(
                'keydown',
                this.documentKeyDownWrapper,
                activeEventListenerOptions
            );
            // moving by selection (not directly by drag)
            this.positionOnSelect = {
                ...this.blockTransformable.$transformState.value.position
            };

            this.moveSubscription = this.moveSelectionService.$deltaMove
                .pipe(takeUntil(this.$destroy))
                .subscribe(this.moveSelection.bind(this));
        }
        this.isSelected = true;
    }
    private ProceedDeselected(): void {
        this.setEditMode(false);
        if (this.isSelected) {
            this._document.body.removeEventListener(
                'keydown',
                this.documentKeyDownWrapper,
                activeEventListenerOptions
            );
            this._document.body.removeEventListener(
                'click',
                this.documentClickWrapper,
                activeEventListenerOptions
            );
            if (this.moveSubscription) this.moveSubscription.unsubscribe();
            this.positionOnSelect = null;
            this.isSelected = false;

            // this.blockModel.$selected.next(false);
            // this.blockBroker.$blockDeselected.next(this.blockModel);
        }
    }
    // TODO: move data position change to block Broker
    private moveSelection(delta: IPoint) {
        if (
            delta &&
            this.positionOnSelect &&
            !this.blockTransformable.isDragging()
        )
            this.blockTransformable.setPosition({
                x: this.positionOnSelect.x + delta.x,
                y: this.positionOnSelect.y + delta.y
            });
    }

    showNote() {
        this.blockBroker.showNote(this.blockModel);
    }
    showDebug() {
        this.blockBroker.showDebug(this.blockModel);
    }

    private documentKeyDownHandler(ev: KeyboardEvent) {
        BlockComponent.keyBindings
            .find(x => x.predicate(this, ev))
            ?.action(this, ev);
    }

    private isBlockInsideClick(ev: MouseEvent | TouchEvent): boolean {
        if (this.block.nativeElement.contains(ev.target as Node)) {
            return true; // is a block
        }
        return false;
    }

    setEditMode(enable: boolean): void {
        this.isEditMode = enable;
        this.blockTransformable.disabled = enable;
        super.setEditMode(enable, model => {
            // TODO: fix this shit
            this.textModel = this.blockModel.text = model;
        });
        this.cdr.markForCheck();
    }
}
