import { OnDestroy, ViewContainerRef } from '@angular/core';
// import { QuillEditorComponent } from 'ngx-quill';
import Quill from 'quill';
import { Subscription } from 'rxjs';
import { NodeText } from '../domain';
import { EditorInputComponent } from '../features/editor/editor-input.component';
import { HasInjector } from './hasInjector';
import { Constructor } from '.';

export function EditableMixin<T extends Constructor<HasInjector>>(
    Base: T = class {} as any
) {
    return class extends Base implements OnDestroy {
        editorInput: EditorInputComponent;
        textModel: string;
        isEditorActive = false;
        editorSubscription: Subscription;

        editorVcr: ViewContainerRef;

        constructor(...args: any[]) {
            super(...args);
        }

        ngOnDestroy(): void {
            this.stopEdit();
        }

        setEditMode(enable: boolean, modelChangeHandler?: any): void {
            if (enable) this.startEdit(modelChangeHandler);
            else this.stopEdit();
        }

        hasFocus(): boolean {
            return (
                this.isEditorActive &&
                this.editorInput &&
                this.editorInput.hasFocus
            );
        }

        q: Quill;

        startEdit(modelChangeHandler: any) {
            if (!this.editorInput) {
                const componentRef =
                    this.editorVcr.createComponent(EditorInputComponent);
                this.isEditorActive = true;
                this.editorInput =
                    componentRef.instance as EditorInputComponent;
                this.editorInput.componentRef = componentRef;
                this.editorInput.model = this.textModel;
                this.editorSubscription =
                    this.editorInput.modelChanged.subscribe(x => {
                        if (modelChangeHandler) modelChangeHandler(x);
                    });
            }
        }
        stopEdit() {
            if (this.editorInput) {
                this.isEditorActive = false;
                this.editorSubscription.unsubscribe();
                this.editorInput.componentRef.destroy();
                delete this.editorInput;
            }
        }
    };
}
