import { Injectable } from '@angular/core';
import { Settings } from '@customer/domain';
import { DragScaleSettingsProvider } from '@drag-scale';
import { BehaviorSubject } from 'rxjs';

const DEFAULT_SETTINGS: Settings = {
    isTouchPad: true,
    applicationTitle: 'StoryTree'
};

@Injectable({
    providedIn: 'root'
})
export class GlobalSettingsService implements DragScaleSettingsProvider {
    constructor() {}

    public $settings = new BehaviorSubject<Settings>(DEFAULT_SETTINGS);

    public setTouchPadMode(mode: boolean): void {
        const s = this.$settings.value;
        s.isTouchPad = mode;
        this.$settings.next(s);
    }
}
