import { IPoint } from '@drag-scale';

const r = (k, x) => /*Math.round(*/ k * x; /*)*/

export function toTopLine(
    startPos: IPoint,
    w0: number,
    h0: number,
    endPos: IPoint,
    w1: number,
    h1: number
): string {
    const w = endPos.x - startPos.x;
    const h = startPos.y - endPos.y;
    // TODO: add logic to determine line direction at blocks
    const halfH0 = r(0.5, h0);
    const s1 = { x: 0, y: -halfH0 };
    const s2 = { x: w - r(0.5, w0 - w1), y: -r(0.5, h0) - h + h1 };
    const vDistance = s2.y - s1.y;
    //const hDistance = pos2.x - pos1.x - w1;
    const hDistance = s1.x - s2.x;
    const s1Slope = { x: s1.x, y: s1.y + r(0.1, vDistance) };
    const s2Slope = { x: s2.x, y: s2.y - r(0.1, vDistance) };

    const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    const smSlope = {
        x: sm.x + r(0.4, hDistance),
        y: sm.y - r(0.2, vDistance)
    };

    const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    return line;
}
export function toDownLine(
    startPos: IPoint,
    w0: number,
    h0: number,
    endPos: IPoint,
    w1: number,
    h1: number
): string {
    const w = endPos.x - startPos.x;
    const h = endPos.y - startPos.y;
    // TODO: add logic to determine line direction at blocks
    const halfH0 = r(0.5, h0);
    const s1 = { x: 0, y: halfH0 };
    const s2 = { x: w - r(0.5, w0 - w1), y: h - r(0.5, h0) };
    const vDistance = s2.y - s1.y;
    //const hDistance = pos2.x - pos1.x - w1;
    const hDistance = s1.x - s2.x;
    const s1Slope = { x: s1.x, y: s1.y + r(0.1, vDistance) };
    const s2Slope = { x: s2.x, y: s2.y - r(0.1, vDistance) };

    const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    const smSlope = {
        x: sm.x + r(0.4, hDistance),
        y: sm.y - r(0.2, vDistance)
    };

    const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    return line;
}

export function toLeftLine(
    startPos: IPoint,
    w0: number,
    h0: number,
    endPos: IPoint,
    w1: number,
    h1: number
): string {
    const w = startPos.x - endPos.x;
    const h = startPos.y - endPos.y;
    // TODO: add logic to determine line direction at blocks
    const halfW0 = r(0.5, w0);
    const s1 = { x: -halfW0, y: 0 };
    const s2 = { x: -(w - w1 + halfW0), y: -r(1, h0 * 0.5 + h - h1 * 0.5) };
    const vDistance = s2.y - s1.y;
    //const hDistance = pos2.x - pos1.x - w1;
    const hDistance = s1.x - s2.x;
    const s1Slope = { x: -(halfW0 + r(0.1, hDistance)), y: 0 };
    const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    const smSlope = {
        x: sm.x + r(0.2, hDistance),
        y: sm.y - r(0.4, vDistance)
    };
    const s2Slope = { x: r(1, -halfW0 - 0.9 * hDistance), y: s2.y };

    const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    return line;
}
export function toRightLine(
    startPos: IPoint,
    w0: number,
    h0: number,
    endPos: IPoint,
    w1: number,
    h1: number
): string {
    // return 'M 0 0, C 25 0, 35 25, 50 50 S 75 100, 100 100';   // sample spline

    // normalize distance by difference
    // zero point - is center of start block
    const w = endPos.x - startPos.x;
    const h = endPos.y - startPos.y;
    // TODO: add logic to determine line direction at blocks
    const halfW0 = r(0.5, w0);
    const s1 = { x: halfW0, y: 0 };
    const s2 = { x: w - halfW0, y: r(0.5, 2 * h - h0 + h1) };
    const vDistance = s2.y - s1.y;
    //const hDistance = pos2.x - pos1.x - w1;
    const hDistance = s2.x - s1.x;
    const s1Slope = { x: halfW0 + r(0.1, hDistance), y: 0 };
    const sm = { x: r(0.5, s2.x + s1.x), y: r(0.5, s2.y + s1.y) };
    const smSlope = {
        x: sm.x - r(0.2, hDistance),
        y: sm.y - r(0.4, vDistance)
    };
    const s2Slope = { x: r(1, w - halfW0 - hDistance / 10), y: s2.y };
    const line = `M ${s1.x} ${s1.y}, C ${s1Slope.x} ${s1Slope.y}, ${smSlope.x} ${smSlope.y}, ${sm.x} ${sm.y}, S ${s2Slope.x} ${s2Slope.y}, ${s2.x} ${s2.y}`;
    return line;
}
