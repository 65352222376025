import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@customer/../environments/environment';
import { Observable, forkJoin, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import IMapKey from '../domain/map.interface';
import { log } from '@vp-util';

const apiUrl = environment.apiBaseUrl;
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

const serialize = function (query: any): string {
    const params = Object.entries(query).map(([key, value]) => {
        // Handle array values by assigning multiple key-value pairs
        if (Array.isArray(value)) {
            // TBD: fix this later
            return value
                .map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
                .join('&');
        }
        // Encode key-value pairs
        return `${encodeURIComponent(key)}=${encodeURIComponent(
            value as string
        )}`;
    });
    return params.join('&');
};

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

    /* == Intention == */
    /*

    Get all maps => GET /maps/
    Get specific map by ID => GET /maps/:id
    ==> Get blocks for specific map ==> GET /blocks?mapId={mapId}
    Add block to map => POST /block
    Remove block => DELETE /block/:id
    Change block => PUT /block/:id



    */
    /* == General Implementation == */
    private getByKey(endpoint: string, key: string) {
        return this.http
            .get(`${apiUrl}/${endpoint}/${key}`, { ...httpOptions })
            .pipe(
                tap(x => {
                    log.debug('getByKey() =>', x);
                }),
                catchError(this.handleError)
            );
    }
    private getByQuery(endpoint: string, query: any) {
        const queryString = serialize(query);
        return this.http
            .get(`${apiUrl}/${endpoint}?${queryString}`, { ...httpOptions })
            .pipe(
                tap(x => {
                    log.debug('getByQuery() =>', x);
                }),
                catchError(this.handleError)
            );
    }

    /* == Specific Implementation == */

    getMap(key: string) {
        //return this.getByKey('maps', key);
        return forkJoin([
            this.getByKey('maps', key),
            this.getByQuery('blocks', { mapId: key })
        ]).pipe(
            catchError(error => {
                // Handle any error that occurs during the combined request
                log.error('Error occurred while fetching map:', error);
                throw error; // or return of([])
            })
        );
    }
    getBlock(key: string) {
        return this.getByKey('blocks', key);
    }

    createMap(data: string) {
        return this.http
            .post(`${apiUrl}/maps`, data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    updateMap(key: string, data: string) {
        return this.http
            .put(`${apiUrl}/maps/${key}`, data, httpOptions)
            .pipe(catchError(this.handleError));
    }

    getMapKeys(): Observable<IMapKey[]> {
        return this.http
            .get(`${apiUrl}/maps`, {
                ...httpOptions,
                params: {
                    fields: ['_id', 'name'].join(',')
                }
            })
            .pipe(
                map((resp: IMapKey[]) => {
                    return resp;
                }),
                tap(x => log.debug(`map keys ${x}`)),
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred.
            log.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            log.error(
                `Error code: ${error.status}, \r\n` + `Content: ${error.error}`
            );
        }
        // Return an observable with a user-facing error message.
        return throwError('Something went wrong: ' + error.message);
    }
}
